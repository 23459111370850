.basis-5 {
  flex-basis: 5%;
}

.sized-5 {
  max-width: 5%;
}

.basis-10 {
  flex-basis: 10%;
}

.sized-10 {
  max-width: 10%;
}

.basis-15 {
  flex-basis: 15%;
}

.sized-15 {
  max-width: 15%;
}

.basis-20 {
  flex-basis: 20%;
}

.sized-20 {
  max-width: 20%;
}

.basis-25 {
  flex-basis: 25%;
}

.sized-25 {
  max-width: 25%;
}

.basis-30 {
  flex-basis: 30%;
}

.sized-30 {
  max-width: 30%;
}

.basis-35 {
  flex-basis: 35%;
}

.sized-35 {
  max-width: 35%;
}

.basis-40 {
  flex-basis: 40%;
}

.sized-40 {
  max-width: 40%;
}

.basis-45 {
  flex-basis: 45%;
}

.sized-45 {
  max-width: 45%;
}

.basis-50 {
  flex-basis: 50%;
}

.sized-50 {
  max-width: 50%;
}

.basis-55 {
  flex-basis: 55%;
}

.sized-55 {
  max-width: 55%;
}

.basis-60 {
  flex-basis: 60%;
}

.sized-60 {
  max-width: 60%;
}

.basis-65 {
  flex-basis: 65%;
}

.sized-65 {
  max-width: 65%;
}

.basis-70 {
  flex-basis: 70%;
}

.sized-70 {
  max-width: 70%;
}

.basis-75 {
  flex-basis: 75%;
}

.sized-75 {
  max-width: 75%;
}

.basis-80 {
  flex-basis: 80%;
}

.sized-80 {
  max-width: 80%;
}

.basis-85 {
  flex-basis: 85%;
}

.sized-85 {
  max-width: 85%;
}

.basis-90 {
  flex-basis: 90%;
}

.sized-90 {
  max-width: 90%;
}

.basis-95 {
  flex-basis: 95%;
}

.sized-95 {
  max-width: 95%;
}

.basis-100 {
  flex-basis: 100%;
}

.sized-100 {
  max-width: 100%;
}

@media screen and (min-width: 991px) {
  .basis-5 {
    max-width: 5%;
  }
  .sized-dt-5,
  .basis-dt-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (min-width: 991px) {
  .basis-10 {
    max-width: 10%;
  }
  .sized-dt-10,
  .basis-dt-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (min-width: 991px) {
  .basis-15 {
    max-width: 15%;
  }
  .sized-dt-15,
  .basis-dt-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (min-width: 991px) {
  .basis-20 {
    max-width: 20%;
  }
  .sized-dt-20,
  .basis-dt-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (min-width: 991px) {
  .basis-25 {
    max-width: 25%;
  }
  .sized-dt-25,
  .basis-dt-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (min-width: 991px) {
  .basis-30 {
    max-width: 30%;
  }
  .sized-dt-30,
  .basis-dt-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (min-width: 991px) {
  .basis-35 {
    max-width: 35%;
  }
  .sized-dt-35,
  .basis-dt-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (min-width: 991px) {
  .basis-40 {
    max-width: 40%;
  }
  .sized-dt-40,
  .basis-dt-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width: 991px) {
  .basis-45 {
    max-width: 45%;
  }
  .sized-dt-45,
  .basis-dt-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (min-width: 991px) {
  .basis-50 {
    max-width: 50%;
  }
  .sized-dt-50,
  .basis-dt-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 991px) {
  .basis-55 {
    max-width: 55%;
  }
  .sized-dt-55,
  .basis-dt-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (min-width: 991px) {
  .basis-60 {
    max-width: 60%;
  }
  .sized-dt-60,
  .basis-dt-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (min-width: 991px) {
  .basis-65 {
    max-width: 65%;
  }
  .sized-dt-65,
  .basis-dt-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (min-width: 991px) {
  .basis-70 {
    max-width: 70%;
  }
  .sized-dt-70,
  .basis-dt-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (min-width: 991px) {
  .basis-75 {
    max-width: 75%;
  }
  .sized-dt-75,
  .basis-dt-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (min-width: 991px) {
  .basis-80 {
    max-width: 80%;
  }
  .sized-dt-80,
  .basis-dt-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (min-width: 991px) {
  .basis-85 {
    max-width: 85%;
  }
  .sized-dt-85,
  .basis-dt-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (min-width: 991px) {
  .basis-90 {
    max-width: 90%;
  }
  .sized-dt-90,
  .basis-dt-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (min-width: 991px) {
  .basis-95 {
    max-width: 95%;
  }
  .sized-dt-95,
  .basis-dt-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (min-width: 991px) {
  .basis-100 {
    max-width: 100%;
  }
  .sized-dt-100,
  .basis-dt-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-5,
  .basis-ls-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-10,
  .basis-ls-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-15,
  .basis-ls-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-20,
  .basis-ls-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-25,
  .basis-ls-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-30,
  .basis-ls-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-35,
  .basis-ls-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-40,
  .basis-ls-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-45,
  .basis-ls-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-50,
  .basis-ls-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-55,
  .basis-ls-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-60,
  .basis-ls-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-65,
  .basis-ls-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-70,
  .basis-ls-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-75,
  .basis-ls-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-80,
  .basis-ls-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-85,
  .basis-ls-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-90,
  .basis-ls-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-95,
  .basis-ls-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-100,
  .basis-ls-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-5,
  .sized-mh-5 {
    max-width: 5%;
  }
  .basis-mobile-h-5,
  .basis-mh-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-5,
  .sized-mv-5 {
    max-width: 5%;
  }
  .basis-mobile-v-5,
  .basis-mv-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-10,
  .sized-mh-10 {
    max-width: 10%;
  }
  .basis-mobile-h-10,
  .basis-mh-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-10,
  .sized-mv-10 {
    max-width: 10%;
  }
  .basis-mobile-v-10,
  .basis-mv-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-15,
  .sized-mh-15 {
    max-width: 15%;
  }
  .basis-mobile-h-15,
  .basis-mh-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-15,
  .sized-mv-15 {
    max-width: 15%;
  }
  .basis-mobile-v-15,
  .basis-mv-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-20,
  .sized-mh-20 {
    max-width: 20%;
  }
  .basis-mobile-h-20,
  .basis-mh-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-20,
  .sized-mv-20 {
    max-width: 20%;
  }
  .basis-mobile-v-20,
  .basis-mv-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-25,
  .sized-mh-25 {
    max-width: 25%;
  }
  .basis-mobile-h-25,
  .basis-mh-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-25,
  .sized-mv-25 {
    max-width: 25%;
  }
  .basis-mobile-v-25,
  .basis-mv-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-30,
  .sized-mh-30 {
    max-width: 30%;
  }
  .basis-mobile-h-30,
  .basis-mh-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-30,
  .sized-mv-30 {
    max-width: 30%;
  }
  .basis-mobile-v-30,
  .basis-mv-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-35,
  .sized-mh-35 {
    max-width: 35%;
  }
  .basis-mobile-h-35,
  .basis-mh-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-35,
  .sized-mv-35 {
    max-width: 35%;
  }
  .basis-mobile-v-35,
  .basis-mv-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-40,
  .sized-mh-40 {
    max-width: 40%;
  }
  .basis-mobile-h-40,
  .basis-mh-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-40,
  .sized-mv-40 {
    max-width: 40%;
  }
  .basis-mobile-v-40,
  .basis-mv-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-45,
  .sized-mh-45 {
    max-width: 45%;
  }
  .basis-mobile-h-45,
  .basis-mh-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-45,
  .sized-mv-45 {
    max-width: 45%;
  }
  .basis-mobile-v-45,
  .basis-mv-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-50,
  .sized-mh-50 {
    max-width: 50%;
  }
  .basis-mobile-h-50,
  .basis-mh-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-50,
  .sized-mv-50 {
    max-width: 50%;
  }
  .basis-mobile-v-50,
  .basis-mv-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-55,
  .sized-mh-55 {
    max-width: 55%;
  }
  .basis-mobile-h-55,
  .basis-mh-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-55,
  .sized-mv-55 {
    max-width: 55%;
  }
  .basis-mobile-v-55,
  .basis-mv-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-60,
  .sized-mh-60 {
    max-width: 60%;
  }
  .basis-mobile-h-60,
  .basis-mh-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-60,
  .sized-mv-60 {
    max-width: 60%;
  }
  .basis-mobile-v-60,
  .basis-mv-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-65,
  .sized-mh-65 {
    max-width: 65%;
  }
  .basis-mobile-h-65,
  .basis-mh-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-65,
  .sized-mv-65 {
    max-width: 65%;
  }
  .basis-mobile-v-65,
  .basis-mv-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-70,
  .sized-mh-70 {
    max-width: 70%;
  }
  .basis-mobile-h-70,
  .basis-mh-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-70,
  .sized-mv-70 {
    max-width: 70%;
  }
  .basis-mobile-v-70,
  .basis-mv-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-75,
  .sized-mh-75 {
    max-width: 75%;
  }
  .basis-mobile-h-75,
  .basis-mh-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-75,
  .sized-mv-75 {
    max-width: 75%;
  }
  .basis-mobile-v-75,
  .basis-mv-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-80,
  .sized-mh-80 {
    max-width: 80%;
  }
  .basis-mobile-h-80,
  .basis-mh-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-80,
  .sized-mv-80 {
    max-width: 80%;
  }
  .basis-mobile-v-80,
  .basis-mv-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-85,
  .sized-mh-85 {
    max-width: 85%;
  }
  .basis-mobile-h-85,
  .basis-mh-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-85,
  .sized-mv-85 {
    max-width: 85%;
  }
  .basis-mobile-v-85,
  .basis-mv-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-90,
  .sized-mh-90 {
    max-width: 90%;
  }
  .basis-mobile-h-90,
  .basis-mh-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-90,
  .sized-mv-90 {
    max-width: 90%;
  }
  .basis-mobile-v-90,
  .basis-mv-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-95,
  .sized-mh-95 {
    max-width: 95%;
  }
  .basis-mobile-h-95,
  .basis-mh-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-95,
  .sized-mv-95 {
    max-width: 95%;
  }
  .basis-mobile-v-95,
  .basis-mv-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-100,
  .sized-mh-100 {
    max-width: 100%;
  }
  .basis-mobile-h-100,
  .basis-mh-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-100,
  .sized-mv-100 {
    max-width: 100%;
  }
  .basis-mobile-v-100,
  .basis-mv-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.wrap-2 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.wrap-2 > * {
  flex: 0 0 calc(100% / 2);
  padding: 15px;
  max-width: calc(100% / 2);
}

.wrap-3 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.wrap-3 > * {
  flex: 0 0 calc(100% / 3);
  padding: 15px;
  max-width: calc(100% / 3);
}

.wrap-4 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.wrap-4 > * {
  flex: 0 0 calc(100% / 4);
  padding: 15px;
  max-width: calc(100% / 4);
}

.wrap-5 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.wrap-5 > * {
  flex: 0 0 calc(100% / 5);
  padding: 15px;
  max-width: calc(100% / 5);
}

.wrap-6 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
.wrap-6 > * {
  flex: 0 0 calc(100% / 6);
  padding: 15px;
  max-width: calc(100% / 6);
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-2 > * {
    flex: 0 0 49%;
    max-width: 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-2 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-3 > * {
    flex: 0 0 49%;
    max-width: 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-3 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-4 > * {
    flex: 0 0 49%;
    max-width: 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-4 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-5 > * {
    flex: 0 0 49%;
    max-width: 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-5 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-6 > * {
    flex: 0 0 49%;
    max-width: 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-6 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-1 > *,
  .wrap-mh-1 > * {
    flex: 0 0 calc(100% / 1);
    max-width: calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-1 > *,
  .wrap-mv-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
    max-width: calc(100% / 1);
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
    max-width: calc(100% / 1);
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
    max-width: calc(100% / 1);
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-2 > *,
  .wrap-mh-2 > * {
    flex: 0 0 calc(100% / 2);
    max-width: calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-2 > *,
  .wrap-mv-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
    max-width: calc(100% / 2);
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
    max-width: calc(100% / 2);
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
    max-width: calc(100% / 2);
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-3 > *,
  .wrap-mh-3 > * {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-3 > *,
  .wrap-mv-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
    max-width: calc(100% / 3);
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
    max-width: calc(100% / 3);
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
    max-width: calc(100% / 3);
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-4 > *,
  .wrap-mh-4 > * {
    flex: 0 0 calc(100% / 4);
    max-width: calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-4 > *,
  .wrap-mv-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
    max-width: calc(100% / 4);
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
    max-width: calc(100% / 4);
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
    max-width: calc(100% / 4);
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-5 > *,
  .wrap-mh-5 > * {
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-5 > *,
  .wrap-mv-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
    max-width: calc(100% / 5);
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
    max-width: calc(100% / 5);
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
    max-width: calc(100% / 5);
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-6 > *,
  .wrap-mh-6 > * {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-6 > *,
  .wrap-mv-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
    max-width: calc(100% / 6);
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
    max-width: calc(100% / 6);
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
    max-width: calc(100% / 6);
  }
}
.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}

.flex-grow-5 {
  flex-grow: 5;
}

.column-gap-0 {
  column-gap: 0rem;
}

.column-gap-1 {
  column-gap: 1rem;
}

.column-gap-2 {
  column-gap: 2rem;
}

.column-gap-3 {
  column-gap: 3rem;
}

.column-gap-4 {
  column-gap: 4rem;
}

.column-gap-5 {
  column-gap: 5rem;
}

.column-gap-6 {
  column-gap: 6rem;
}

.column-gap-7 {
  column-gap: 7rem;
}

.column-gap-8 {
  column-gap: 8rem;
}

.column-gap-9 {
  column-gap: 9rem;
}

.column-gap-10 {
  column-gap: 10rem;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: inherit;
}

ul li {
  list-style-type: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

img,
embed,
iframe,
object,
audio,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

a {
  color: inherit;
}

a:not(:disabled), a:not(.disabled), button:not(:disabled), button:not(.disabled) {
  cursor: pointer;
}

button {
  line-height: inherit;
  font-size: inherit;
}

textarea:focus, input:focus {
  outline: none;
}

section {
  padding: 3rem 0;
}
section.fh {
  min-height: 100vh;
}

.container {
  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
}
.container.super-slim {
  max-width: 800px;
}
.container.slim {
  max-width: 900px;
}
.container.narrow {
  max-width: 1080px;
}
.container.wider {
  max-width: 1400px;
}
.container.wide {
  max-width: 1600px;
}
.container.widest {
  max-width: 1875px;
}
.container.largescreen {
  max-width: 2430px;
}
.container.fw {
  max-width: none !important;
}
@media screen and (max-width: 1249px) {
  .container {
    padding: 0 calc(15px + 2%);
  }
}
@media screen and (max-width: 990px) {
  .container {
    padding: 0 calc(15px + 1%);
  }
}

.row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}
@media screen and (max-width: 990px) {
  .row {
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
  }
  .row.eq-h {
    height: auto !important;
  }
}
@media screen and (max-width: 990px) {
  .row.no-collapse {
    display: flex;
  }
  .row.no-collapse .col {
    margin-bottom: 0;
  }
}

.col {
  padding: 0 15px;
  flex-shrink: 0;
  box-sizing: border-box;
}
.col:not([class*=basis]) {
  flex-basis: 0;
  flex-grow: 1;
}
.col[class*=basis] {
  flex-grow: 0;
}
@media screen and (max-width: 990px) {
  .col {
    margin-bottom: 1rem;
  }
  .col:last-of-type {
    margin-bottom: 0;
  }
}

.masonry > div {
  display: flex;
  flex-direction: column;
}
.masonry-inner {
  border: 1px solid #d4d4d4;
  padding: 1rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  display: block;
  flex-grow: 1;
}
.masonry a.masonry-inner:hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
}
.masonry-inverted .masonry-inner {
  box-shadow: none;
}

.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.d-inline {
  display: inline;
}
.d-none {
  display: none;
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .d-mh-flex {
    display: flex;
  }
  .d-mh-inline-flex {
    display: inline-flex;
  }
  .d-mh-block {
    display: block;
  }
  .d-mh-inline-block {
    display: inline-block;
  }
  .d-mh-inline {
    display: inline;
  }
  .d-mh-none {
    display: none;
  }
}
@media screen and (max-width: 479px) {
  .d-mv-flex {
    display: flex;
  }
  .d-mv-inline-flex {
    display: inline-flex;
  }
  .d-mv-block {
    display: block;
  }
  .d-mv-inline-block {
    display: inline-block;
  }
  .d-mv-inline {
    display: inline;
  }
  .d-mv-none {
    display: none;
  }
}
@media screen and (min-width: 991px) {
  .d-r-flex, .d-dt-flex {
    display: flex;
  }
  .d-r-inline-flex, .d-dt-inline-flex {
    display: inline-flex;
  }
  .d-r-block, .d-dt-block {
    display: block;
  }
  .d-r-inline-block, .d-dt-inline-block {
    display: inline-block;
  }
  .d-r-inline, .d-dt-inline {
    display: inline;
  }
  .d-r-none, .d-dt-none {
    display: none;
  }
}
@media screen and (min-width: 2430px) {
  .d-ls-flex {
    display: flex;
  }
  .d-ls-inline-flex {
    display: inline-flex;
  }
  .d-ls-block {
    display: block;
  }
  .d-ls-inline-block {
    display: inline-block;
  }
  .d-ls-inline {
    display: inline;
  }
  .d-ls-none {
    display: none;
  }
}

.p-static {
  position: static;
}
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.p-sticky {
  position: sticky;
}

.border {
  border-width: 0;
  border-style: solid;
  border-color: #000000;
}
.border-all-width-0 {
  border-width: 0px;
}
.border-all-width-1 {
  border-width: 1px;
}
.border-all-width-2 {
  border-width: 2px;
}
.border-all-width-3 {
  border-width: 3px;
}
.border-all-width-4 {
  border-width: 4px;
}
.border-all-width-5 {
  border-width: 5px;
}
.border-all-width-6 {
  border-width: 6px;
}
.border-all-width-7 {
  border-width: 7px;
}
.border-all-width-8 {
  border-width: 8px;
}
.border-all-width-9 {
  border-width: 9px;
}
.border-all-width-10 {
  border-width: 10px;
}
.border-top-width-0 {
  border-top-width: 0px;
}
.border-top-width-1 {
  border-top-width: 1px;
}
.border-top-width-2 {
  border-top-width: 2px;
}
.border-top-width-3 {
  border-top-width: 3px;
}
.border-top-width-4 {
  border-top-width: 4px;
}
.border-top-width-5 {
  border-top-width: 5px;
}
.border-top-width-6 {
  border-top-width: 6px;
}
.border-top-width-7 {
  border-top-width: 7px;
}
.border-top-width-8 {
  border-top-width: 8px;
}
.border-top-width-9 {
  border-top-width: 9px;
}
.border-top-width-10 {
  border-top-width: 10px;
}
.border-bottom-width-0 {
  border-bottom-width: 0px;
}
.border-bottom-width-1 {
  border-bottom-width: 1px;
}
.border-bottom-width-2 {
  border-bottom-width: 2px;
}
.border-bottom-width-3 {
  border-bottom-width: 3px;
}
.border-bottom-width-4 {
  border-bottom-width: 4px;
}
.border-bottom-width-5 {
  border-bottom-width: 5px;
}
.border-bottom-width-6 {
  border-bottom-width: 6px;
}
.border-bottom-width-7 {
  border-bottom-width: 7px;
}
.border-bottom-width-8 {
  border-bottom-width: 8px;
}
.border-bottom-width-9 {
  border-bottom-width: 9px;
}
.border-bottom-width-10 {
  border-bottom-width: 10px;
}
.border-left-width-0 {
  border-left-width: 0px;
}
.border-left-width-1 {
  border-left-width: 1px;
}
.border-left-width-2 {
  border-left-width: 2px;
}
.border-left-width-3 {
  border-left-width: 3px;
}
.border-left-width-4 {
  border-left-width: 4px;
}
.border-left-width-5 {
  border-left-width: 5px;
}
.border-left-width-6 {
  border-left-width: 6px;
}
.border-left-width-7 {
  border-left-width: 7px;
}
.border-left-width-8 {
  border-left-width: 8px;
}
.border-left-width-9 {
  border-left-width: 9px;
}
.border-left-width-10 {
  border-left-width: 10px;
}
.border-right-width-0 {
  border-right-width: 0px;
}
.border-right-width-1 {
  border-right-width: 1px;
}
.border-right-width-2 {
  border-right-width: 2px;
}
.border-right-width-3 {
  border-right-width: 3px;
}
.border-right-width-4 {
  border-right-width: 4px;
}
.border-right-width-5 {
  border-right-width: 5px;
}
.border-right-width-6 {
  border-right-width: 6px;
}
.border-right-width-7 {
  border-right-width: 7px;
}
.border-right-width-8 {
  border-right-width: 8px;
}
.border-right-width-9 {
  border-right-width: 9px;
}
.border-right-width-10 {
  border-right-width: 10px;
}
.border-color-yellow {
  border-color: #ffdb3c;
}
.border-color-purple {
  border-color: #720067;
}
.border-color-white {
  border-color: #fff;
}
.border-color-beige {
  border-color: #f7f7f7;
}
.border-color-gray {
  border-color: #d4d4d4;
}
.border-color-black {
  border-color: #000000;
}
.border-color-black-alt {
  border-color: #2b2b33;
}
.border-color-base {
  border-color: #292929;
}
.border-color-red {
  border-color: #f20042;
}
.border-color-orange {
  border-color: #ff9100;
}
.border-color-scarlet {
  border-color: #f44336;
}
.border-color-green {
  border-color: #64dd17;
}
.border-color-blue {
  border-color: #00b0ff;
}
.border-color-pink {
  border-color: #ea80fc;
}
.border-style-dotted {
  border-style: dotted;
}
.border-style-dashed {
  border-style: dashed;
}
.border-style-solid {
  border-style: solid;
}
.border-style-double {
  border-top-style: double;
  border-bottom-style: double;
  border-left-style: double;
  border-right-style: double;
}
.border-radius-4 {
  border-radius: 4px;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-6 {
  border-radius: 6px;
}
.border-radius-7 {
  border-radius: 7px;
}
.border-radius-8 {
  border-radius: 8px;
}
.border-radius-9 {
  border-radius: 9px;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-rounded {
  border-radius: 50%;
}

.flex-aic {
  align-items: center;
}
.flex-ait {
  align-items: flex-start;
}
.flex-aib {
  align-items: flex-end;
}
.flex-jcs {
  justify-content: flex-start;
}
.flex-jce {
  justify-content: flex-end;
}
.flex-jcc {
  justify-content: center;
}
.flex-jcsb {
  justify-content: space-between;
}
.flex-jcsa {
  justify-content: space-around;
}
.flex-direction-column {
  flex-direction: column;
}
.flex-direction-column-reverse {
  flex-direction: column-reverse;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-direction-row-reverse {
  flex-direction: row-reverse;
}
@media screen and (min-width: 991px) {
  .flex-direction-dt-column {
    flex-direction: column;
  }
  .flex-direction-dt-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-direction-dt-row {
    flex-direction: row;
  }
  .flex-direction-dt-row-reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 2430px) {
  .flex-direction-ls-column {
    flex-direction: column;
  }
  .flex-direction-ls-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-direction-ls-row {
    flex-direction: row;
  }
  .flex-direction-ls-row-reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .flex-direction-mh-column {
    flex-direction: column;
  }
  .flex-direction-mh-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-direction-mh-row {
    flex-direction: row;
  }
  .flex-direction-mh-row-reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (max-width: 479px) {
  .flex-direction-mv-column {
    flex-direction: column;
  }
  .flex-direction-mv-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-direction-mv-row {
    flex-direction: row;
  }
  .flex-direction-mv-row-reverse {
    flex-direction: row-reverse;
  }
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.text-uc {
  text-transform: uppercase;
}
.text-lc {
  text-transform: lowercase;
}
.text-cz {
  text-transform: capitalize;
}
@media screen and (min-width: 991px) {
  .text-r-left, .text-dt-left {
    text-align: left;
  }
  .text-r-center, .text-dt-center {
    text-align: center;
  }
  .text-r-right, .text-dt-right {
    text-align: right;
  }
  .text-r-justify, .text-dt-justify {
    text-align: justify;
  }
}
@media screen and (min-width: 2430px) {
  .text-ls-left {
    text-align: left;
  }
  .text-ls-center {
    text-align: center;
  }
  .text-ls-right {
    text-align: right;
  }
  .text-ls-justify {
    text-align: justify;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .text-mh-left {
    text-align: left;
  }
  .text-mh-center {
    text-align: center;
  }
  .text-mh-right {
    text-align: right;
  }
  .text-mh-justify {
    text-align: justify;
  }
}
@media screen and (max-width: 479px) {
  .text-mv-left {
    text-align: left;
  }
  .text-mv-center {
    text-align: center;
  }
  .text-mv-right {
    text-align: right;
  }
  .text-mv-justify {
    text-align: justify;
  }
}

.float-container:after {
  content: "";
  display: block;
  clear: both;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
@media screen and (min-width: 991px) {
  .float-dt-left {
    float: left;
  }
  .float-dt-right {
    float: right;
  }
}
@media screen and (min-width: 2430px) {
  .float-ls-left {
    float: left;
  }
  .float-ls-right {
    float: right;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .float-mh-left {
    float: left;
  }
  .float-mh-right {
    float: right;
  }
}
@media screen and (max-width: 479px) {
  .float-mv-left {
    float: left;
  }
  .float-mv-right {
    float: right;
  }
}

.font-size-xs {
  font-size: 60%;
}
.font-size-sm {
  font-size: 80%;
}
.font-size-n {
  font-size: 100%;
}
.font-size-l {
  font-size: 125%;
}
.font-size-xl {
  font-size: 150%;
}
.font-size-xxl {
  font-size: 200%;
}
.font-size-xxxl {
  font-size: 300%;
}
.font-size-xxxxl {
  font-size: 400%;
}
.font-size-xxxxxl {
  font-size: 500%;
}
@media screen and (min-width: 991px) {
  .font-size-dt-xs {
    font-size: 60%;
  }
  .font-size-dt-sm {
    font-size: 80%;
  }
  .font-size-dt-n {
    font-size: 100%;
  }
  .font-size-dt-l {
    font-size: 125%;
  }
  .font-size-dt-xl {
    font-size: 150%;
  }
  .font-size-dt-xxl {
    font-size: 200%;
  }
  .font-size-dt-xxxl {
    font-size: 300%;
  }
  .font-size-dt-xxxxl {
    font-size: 400%;
  }
  .font-size-dt-xxxxxl {
    font-size: 500%;
  }
}
@media screen and (min-width: 2430px) {
  .font-size-ls-xs {
    font-size: 60%;
  }
  .font-size-ls-sm {
    font-size: 80%;
  }
  .font-size-ls-n {
    font-size: 100%;
  }
  .font-size-ls-l {
    font-size: 125%;
  }
  .font-size-ls-xl {
    font-size: 150%;
  }
  .font-size-ls-xxl {
    font-size: 200%;
  }
  .font-size-ls-xxxl {
    font-size: 300%;
  }
  .font-size-ls-xxxxl {
    font-size: 400%;
  }
  .font-size-ls-xxxxxl {
    font-size: 500%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .font-size-mh-xs {
    font-size: 60%;
  }
  .font-size-mh-sm {
    font-size: 80%;
  }
  .font-size-mh-n {
    font-size: 100%;
  }
  .font-size-mh-l {
    font-size: 125%;
  }
  .font-size-mh-xl {
    font-size: 150%;
  }
  .font-size-mh-xxl {
    font-size: 200%;
  }
  .font-size-mh-xxxl {
    font-size: 300%;
  }
  .font-size-mh-xxxxl {
    font-size: 400%;
  }
  .font-size-mh-xxxxxl {
    font-size: 500%;
  }
}
@media screen and (max-width: 479px) {
  .font-size-mv-xs {
    font-size: 60%;
  }
  .font-size-mv-sm {
    font-size: 80%;
  }
  .font-size-mv-n {
    font-size: 100%;
  }
  .font-size-mv-l {
    font-size: 125%;
  }
  .font-size-mv-xl {
    font-size: 150%;
  }
  .font-size-mv-xxl {
    font-size: 200%;
  }
  .font-size-mv-xxxl {
    font-size: 300%;
  }
  .font-size-mv-xxxxl {
    font-size: 400%;
  }
  .font-size-mv-xxxxxl {
    font-size: 500%;
  }
}

.font-weight-100 {
  font-weight: 100;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-900 {
  font-weight: 900;
}

.line-height-inherit {
  line-height: inherit;
}
.line-height-normal, .line-height-n {
  line-height: normal;
}
.line-height-60 {
  line-height: 60%;
}
.line-height-80 {
  line-height: 80%;
}
.line-height-100 {
  line-height: 100%;
}
.line-height-125 {
  line-height: 125%;
}
.line-height-150 {
  line-height: 150%;
}
.line-height-200 {
  line-height: 200%;
}

.ls-min {
  letter-spacing: -0.1em;
}
.ls-condenced {
  letter-spacing: -0.05em;
}
.ls-normal {
  letter-spacing: normal;
}
.ls-wide {
  letter-spacing: 0.05em;
}
.ls-max {
  letter-spacing: 0.1em;
}

.hidden {
  display: none !important;
}
@media screen and (max-width: 990px) {
  .hidden-mobile {
    display: none !important;
  }
}
@media screen and (min-width: 991px) {
  .hidden-desktop {
    display: none !important;
  }
}

.visibility-on {
  visibility: visible;
}
.visibility-off {
  visibility: hidden;
}

.overflow-visible {
  overflow: visible;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-hidden {
  overflow: hidden;
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
@media screen and (min-width: 991px) {
  .m-dt-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 991px) {
  .ml-dt-auto {
    margin-left: auto;
  }
}
@media screen and (min-width: 991px) {
  .mr-dt-auto {
    margin-right: auto;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 2430px) {
  .ml-ls-auto {
    margin-left: auto;
  }
}
@media screen and (min-width: 2430px) {
  .mr-ls-auto {
    margin-right: auto;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .ml-mh-auto {
    margin-left: auto;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mr-mh-auto {
    margin-right: auto;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 479px) {
  .ml-mv-auto {
    margin-left: auto;
  }
}
@media screen and (max-width: 479px) {
  .mr-mv-auto {
    margin-right: auto;
  }
}

.no-bg {
  background-image: none !important;
}

.underline {
  text-decoration: underline;
}

.shadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.shadow-small {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.shadow-big {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
}

.pr {
  position: relative;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.media-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.media-wrapper iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.object-fit-cover {
  object-fit: cover;
}
.object-fit-contain {
  object-fit: contain;
}

.clearfix:after {
  display: table;
  content: "";
  clear: both;
}

.white-space-nowrap {
  white-space: nowrap;
}
.white-space-normal {
  white-space: normal;
}
.white-space-pre {
  white-space: pre;
}

.word-break-normal {
  word-break: normal;
}
.word-break-all {
  word-break: break-all;
}
.word-break-word {
  word-break: break-word;
}

.bfv-hidden {
  backface-visibility: hidden;
}
.bfv-visible {
  backface-visibility: visible;
}

.acc-content {
  display: none;
}
.acc .btn-acc span:first-child {
  display: inline-block;
}
.acc .btn-acc span:last-child {
  display: none;
}
.acc-opened .acc-content {
  display: block;
}
.acc-opened .btn-acc span:first-child {
  display: none;
}
.acc-opened .btn-acc span:last-child {
  display: inline-block;
}

.tab-titles {
  margin-bottom: 0;
}
.tab-titles li {
  list-style-type: none;
  margin-left: 0;
}
.tab-titles li a {
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
}
.tab-titles li a:not(.active) {
  background-color: inherit !important;
  color: inherit !important;
}
.tab-titles li a:hover {
  opacity: 1;
}
.tab-info {
  padding: 1rem;
}
.tab-info div[data-item-tab] {
  display: none;
}
.tab-info div[data-item-tab].active {
  display: block;
}
.tab-horizontal .tab-titles {
  display: flex;
  overflow-x: auto;
}
.tab-vertical {
  display: flex;
}
.tab-vertical .tab-info {
  flex-grow: 1;
}

.banner-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.banner-tile {
  background-repeat: repeat;
}
@media screen and (min-width: 991px) {
  .banner-fixed {
    background-attachment: fixed;
  }
}

.opacity-panel {
  position: relative;
}
.opacity-panel-bg {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.opacity-panel-content {
  position: relative;
  z-index: 5;
}

.op-0 {
  opacity: 0;
}

.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-8 {
  opacity: 0.8;
}

.op-9 {
  opacity: 0.9;
}

.op-10 {
  opacity: 1;
}

.img-cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.img-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.text-yellow {
  color: #ffdb3c;
}
.text-purple {
  color: #720067;
}
.text-white {
  color: #fff;
}
.text-beige {
  color: #f7f7f7;
}
.text-gray {
  color: #d4d4d4;
}
.text-black {
  color: #000000;
}
.text-black-alt {
  color: #2b2b33;
}
.text-base {
  color: #292929;
}
.text-darkgray {
  color: #3b3b3b;
}
.text-orange {
  color: #ff9100;
}
.text-scarlet {
  color: #f44336;
}
.text-red {
  color: #f20042;
}
.text-pink {
  color: #ea80fc;
}
.text-blue {
  color: #00b0ff;
}
.text-green {
  color: #64dd17;
}

.bg-yellow {
  background-color: #ffdb3c;
}
.bg-purple {
  background-color: #720067;
}
.bg-white {
  background-color: #fff;
}
.bg-beige {
  background-color: #f7f7f7;
}
.bg-gray {
  background-color: #d4d4d4;
}
.bg-darkgray {
  background-color: #3b3b3b;
}
.bg-black {
  background-color: #000000;
}
.bg-black-alt {
  background-color: #2b2b33;
}
.bg-base {
  background-color: #292929;
}
.bg-orange {
  background-color: #ff9100;
}
.bg-scarlet {
  background-color: #f44336;
}
.bg-red {
  background-color: #f20042;
}
.bg-pink {
  background-color: #ea80fc;
}
.bg-blue {
  background-color: #00b0ff;
}
.bg-green {
  background-color: #64dd17;
}

body.loaded a, body.loaded button {
  transition: all 0.4s ease-in-out;
}

a,
button {
  transition: none;
}
a:not(.disabled):not(:disabled),
button:not(.disabled):not(:disabled) {
  cursor: pointer;
}
a.disabled, a:disabled,
button.disabled,
button:disabled {
  cursor: default;
  pointer-events: none;
}

a {
  text-decoration: underline;
}
a:not(.btn):hover {
  opacity: 0.75;
}

html {
  font-size: 20px;
}
@media screen and (max-width: 990px) {
  html {
    font-size: 16px;
  }
}

body {
  line-height: 1.5;
  color: #292929;
}

h1, h2, h3, h4, h5, h6,
.h1-style, .h2-style, .h3-style, .h4-style, .h5-style, .h6-style {
  line-height: normal;
}

h1,
.h1-style {
  font-size: 2em;
}

h2,
.h2-style {
  font-size: 1.5em;
}

h3,
.h3-style {
  font-size: 1.3em;
}

h4,
.h4-style {
  font-size: 1.2em;
}

h5,
.h5-style {
  font-size: 1.1em;
}

h6,
.h6-style {
  font-size: 1em;
}

p, ul, ol {
  margin-bottom: 1em;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.6em;
}

blockquote {
  border-left: 0.5rem solid currentColor;
  padding: 2rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
  font-style: italic;
}
blockquote > *:last-child {
  margin-bottom: 0;
}

section ul li {
  list-style-type: disc;
  margin-left: 1em;
}
section ol li {
  margin-left: 1em;
}

ul.list-style-none li {
  list-style-type: none !important;
}
ul.list-style-dots li {
  list-style-type: disc !important;
}
ul.list-style-triangle li {
  list-style-type: disclosure-closed !important;
}

header a,
footer a {
  display: inline-block;
}

.design-block a:not(.default) {
  text-decoration: none;
}
.design-block a:not(.default):not(.btn):hover {
  opacity: 1;
}
.design-block ul:not(.default) li, .design-block ol:not(.default) li {
  list-style-type: none;
}

img.img-fw {
  width: 100%;
}

a obj {
  pointer-events: none;
}

.btn {
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 400;
  border: 1px solid #000000;
  color: #000000;
  background-color: transparent;
  display: inline-block;
}
.btn.active, .btn:hover {
  background-color: #000000;
  color: #fff;
}
@media screen and (max-width: 990px) {
  .btn {
    font-size: 1rem;
  }
}
@media screen and (min-width: 991px) {
  .btn {
    min-width: 185px;
  }
}
.btn.bold {
  font-weight: 700;
}
.btn-small {
  font-size: 0.7rem;
}
@media screen and (max-width: 990px) {
  .btn-small {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 991px) {
  .btn-small {
    min-width: 125px;
  }
}
.btn-large {
  font-size: 1rem;
}
@media screen and (max-width: 990px) {
  .btn-large {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 991px) {
  .btn-large {
    min-width: 220px;
  }
}
.btn-inverted {
  background-color: #000000;
  color: #fff;
}
.btn-inverted:hover {
  background-color: #fff;
  color: #000000;
}
.btn-white {
  background-color: #fff;
}
.btn-white:hover {
  background-color: #000000;
  color: #fff;
}
.btn-white-dimm {
  background-color: #fff;
  color: #000000;
  border: 1px solid #fff;
}
.btn-white-dimm:hover {
  background-color: #fff;
  color: #000000;
  filter: brightness(90%);
}
.btn-gray {
  border: 1px solid #d4d4d4;
  color: #d4d4d4;
}
.btn-gray:hover {
  background-color: #d4d4d4;
  color: #000000;
}
.btn-gray-inverted {
  border: 1px solid #d4d4d4;
  background-color: #d4d4d4;
  color: #000000;
}
.btn-gray-inverted:hover {
  border: 1px solid #d4d4d4;
  background-color: transparent;
  color: #d4d4d4;
}
.btn-gray-dimm {
  background-color: #d4d4d4;
  color: #000000;
  border: 1px solid #d4d4d4;
}
.btn-gray-dimm:hover {
  background-color: #d4d4d4;
  color: #000000;
  filter: brightness(90%);
}
.btn-darkgray {
  border: 1px solid #3b3b3b;
  color: #3b3b3b;
}
.btn-darkgray:hover {
  background-color: #3b3b3b;
  color: #fff;
}
.btn-darkgray-inverted {
  border: 1px solid #3b3b3b;
  background-color: #3b3b3b;
  color: #fff;
}
.btn-darkgray-inverted:hover {
  border: 1px solid #3b3b3b;
  background-color: transparent;
  color: #3b3b3b;
}
.btn-darkgray-dimm {
  background-color: #3b3b3b;
  color: #fff;
  border: 1px solid #3b3b3b;
}
.btn-darkgray-dimm:hover {
  background-color: #3b3b3b;
  color: #fff;
  filter: brightness(90%);
}
.btn-yellow {
  border: 1px solid #ffdb3c;
  color: #ffdb3c;
}
.btn-yellow:hover {
  background-color: #ffdb3c;
  color: #292929;
}
.btn-yellow-inverted {
  border: 1px solid #ffdb3c;
  background-color: #ffdb3c;
  color: #292929;
}
.btn-yellow-inverted:hover {
  border: 1px solid #ffdb3c;
  background-color: transparent;
  color: #ffdb3c;
}
.btn-yellow-dimm {
  background-color: #ffdb3c;
  color: #292929;
  border: 1px solid #ffdb3c;
}
.btn-yellow-dimm:hover {
  background-color: #ffdb3c;
  color: #292929;
  filter: brightness(90%);
}
.btn-purple {
  border: 1px solid #720067;
  color: #720067;
}
.btn-purple:hover {
  background-color: #720067;
  color: #fff;
}
.btn-purple-inverted {
  border: 1px solid #fff;
  background-color: #720067;
  color: #fff;
}
.btn-purple-inverted:hover {
  border: 1px solid #720067;
  background-color: transparent;
  color: #720067;
}
.btn-purple-dimm {
  background-color: #720067;
  color: #fff;
  border: 1px solid #720067;
}
.btn-purple-dimm:hover {
  background-color: #720067;
  color: #fff;
  filter: brightness(90%);
}
.btn-pink {
  border: 1px solid #ea80fc;
  color: #ea80fc;
}
.btn-pink:hover {
  background-color: #ea80fc;
  color: #292929;
}
.btn-pink-inverted {
  border: 1px solid #ea80fc;
  background-color: #ea80fc;
  color: #292929;
}
.btn-pink-inverted:hover {
  border: 1px solid #ea80fc;
  background-color: transparent;
  color: #ea80fc;
}
.btn-pink-dimm {
  background-color: #ea80fc;
  color: #292929;
  border: 1px solid #ea80fc;
}
.btn-pink-dimm:hover {
  background-color: #ea80fc;
  color: #292929;
  filter: brightness(90%);
}
.btn-blue {
  border: 1px solid #00b0ff;
  color: #00b0ff;
}
.btn-blue:hover {
  background-color: #00b0ff;
  color: #292929;
}
.btn-blue-inverted {
  border: 1px solid #00b0ff;
  background-color: #00b0ff;
  color: #292929;
}
.btn-blue-inverted:hover {
  border: 1px solid #00b0ff;
  background-color: transparent;
  color: #00b0ff;
}
.btn-blue-dimm {
  background-color: #00b0ff;
  color: #292929;
  border: 1px solid #00b0ff;
}
.btn-blue-dimm:hover {
  background-color: #00b0ff;
  color: #292929;
  filter: brightness(90%);
}
.btn-green {
  border: 1px solid #64dd17;
  color: #64dd17;
}
.btn-green:hover {
  background-color: #64dd17;
  color: #292929;
}
.btn-green-inverted {
  border: 1px solid #64dd17;
  background-color: #64dd17;
  color: #292929;
}
.btn-green-inverted:hover {
  border: 1px solid #64dd17;
  background-color: transparent;
  color: #64dd17;
}
.btn-green-dimm {
  background-color: #64dd17;
  color: #292929;
  border: 1px solid #64dd17;
}
.btn-green-dimm:hover {
  background-color: #64dd17;
  color: #292929;
  filter: brightness(90%);
}
.btn-scarlet {
  border: 1px solid #f44336;
  color: #f44336;
}
.btn-scarlet:hover {
  background-color: #f44336;
  color: #fff;
}
.btn-scarlet-inverted {
  background-color: #f44336;
  border: 1px solid #f44336;
  color: #fff;
}
.btn-scarlet-inverted:hover {
  border: 1px solid #f44336;
  background-color: transparent;
  color: #f44336;
}
.btn-scarlet-dimm {
  background-color: #f44336;
  color: #fff;
  border: 1px solid #f44336;
}
.btn-scarlet-dimm:hover {
  background-color: #f44336;
  color: #fff;
  filter: brightness(90%);
}
.btn-orange {
  border: 1px solid #ff9100;
  color: #ff9100;
}
.btn-orange:hover {
  background-color: #ff9100;
  color: #fff;
}
.btn-orange-inverted {
  background-color: #ff9100;
  color: #fff;
  border: 1px solid #ff9100;
}
.btn-orange-inverted:hover {
  border: 1px solid #ff9100;
  background-color: transparent;
  color: #ff9100;
}
.btn-orange-dimm {
  background-color: #ff9100;
  color: #fff;
  border: 1px solid #ff9100;
}
.btn-orange-dimm:hover {
  background-color: #ff9100;
  color: #fff;
  filter: brightness(90%);
}
.btn-red {
  border: 1px solid #f20042;
  color: #f20042;
}
.btn-red:hover {
  background-color: #f20042;
  color: #fff;
}
.btn-red-inverted {
  background-color: #f20042;
  border: 1px solid #f20042;
  color: #fff;
}
.btn-red-inverted:hover {
  border: 1px solid #f20042;
  background-color: transparent;
  color: #f20042;
}
.btn-red-dimm {
  background-color: #f20042;
  color: #fff;
  border: 1px solid #f20042;
}
.btn-red-dimm:hover {
  background-color: #f20042;
  color: #fff;
  filter: brightness(90%);
}
.btn-rounded {
  border-radius: 1rem;
}
.btn[class^=btn-animated], .btn[class*=" btn-animated"] {
  border: none !important;
  position: relative;
  overflow: hidden;
}
.btn[class^=btn-animated] span, .btn[class*=" btn-animated"] span {
  z-index: 5;
  position: relative;
}
.btn[class^=btn-animated]:hover:before, .btn[class*=" btn-animated"]:hover:before {
  right: -50%;
  top: -50%;
}
.btn[class^=btn-animated]:before, .btn[class*=" btn-animated"]:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  top: -5px;
  transition: all 0.7s;
  width: 200%;
  height: 300%;
  right: 100%;
}
.btn[class^=btn-animated][class*=-from-white], .btn[class*=" btn-animated"][class*=-from-white] {
  background-color: #fff;
  color: #000000;
}
.btn[class^=btn-animated][class*=-from-black], .btn[class*=" btn-animated"][class*=-from-black] {
  background-color: #000000;
  color: #fff;
}
.btn[class^=btn-animated][class*=-from-gray], .btn[class*=" btn-animated"][class*=-from-gray] {
  background-color: #d4d4d4;
  color: #000000;
}
.btn[class^=btn-animated][class*=-from-darkgray], .btn[class*=" btn-animated"][class*=-from-darkgray] {
  background-color: #3b3b3b;
  color: #fff;
}
.btn[class^=btn-animated][class*=-from-blue], .btn[class*=" btn-animated"][class*=-from-blue] {
  background-color: #00b0ff;
  color: #000000;
}
.btn[class^=btn-animated][class*=-from-green], .btn[class*=" btn-animated"][class*=-from-green] {
  background-color: #64dd17;
  color: #000000;
}
.btn[class^=btn-animated][class*=-from-pink], .btn[class*=" btn-animated"][class*=-from-pink] {
  background-color: #ea80fc;
  color: #000000;
}
.btn[class^=btn-animated][class*=-from-yellow], .btn[class*=" btn-animated"][class*=-from-yellow] {
  background-color: #ffdb3c;
  color: #fff;
}
.btn[class^=btn-animated][class*=-from-red], .btn[class*=" btn-animated"][class*=-from-red] {
  background-color: #f20042;
  color: #fff;
}
.btn[class^=btn-animated][class*=-from-orange], .btn[class*=" btn-animated"][class*=-from-orange] {
  background-color: #ff9100;
  color: #fff;
}
.btn[class^=btn-animated][class*=-from-scarlet], .btn[class*=" btn-animated"][class*=-from-scarlet] {
  background-color: #f44336;
  color: #fff;
}
.btn[class^=btn-animated][class*=-from-purple], .btn[class*=" btn-animated"][class*=-from-purple] {
  background-color: #720067;
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-pink]:hover, .btn[class*=" btn-animated"][class*=-to-pink]:hover {
  color: #000000;
}
.btn[class^=btn-animated][class*=-to-pink]:before, .btn[class*=" btn-animated"][class*=-to-pink]:before {
  background-color: #ea80fc;
}
.btn[class^=btn-animated][class*=-to-blue]:hover, .btn[class*=" btn-animated"][class*=-to-blue]:hover {
  color: #000000;
}
.btn[class^=btn-animated][class*=-to-blue]:before, .btn[class*=" btn-animated"][class*=-to-blue]:before {
  background-color: #00b0ff;
}
.btn[class^=btn-animated][class*=-to-green]:hover, .btn[class*=" btn-animated"][class*=-to-green]:hover {
  color: #000000;
}
.btn[class^=btn-animated][class*=-to-green]:before, .btn[class*=" btn-animated"][class*=-to-green]:before {
  background-color: #64dd17;
}
.btn[class^=btn-animated][class*=-to-yellow]:hover, .btn[class*=" btn-animated"][class*=-to-yellow]:hover {
  color: #000000;
}
.btn[class^=btn-animated][class*=-to-yellow]:before, .btn[class*=" btn-animated"][class*=-to-yellow]:before {
  background-color: #ffdb3c;
}
.btn[class^=btn-animated][class*=-to-red]:hover, .btn[class*=" btn-animated"][class*=-to-red]:hover {
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-red]:before, .btn[class*=" btn-animated"][class*=-to-red]:before {
  background-color: #f20042;
}
.btn[class^=btn-animated][class*=-to-purple]:hover, .btn[class*=" btn-animated"][class*=-to-purple]:hover {
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-purple]:before, .btn[class*=" btn-animated"][class*=-to-purple]:before {
  background-color: #720067;
}
.btn[class^=btn-animated][class*=-to-scarlet]:hover, .btn[class*=" btn-animated"][class*=-to-scarlet]:hover {
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-scarlet]:before, .btn[class*=" btn-animated"][class*=-to-scarlet]:before {
  background-color: #f44336;
}
.btn[class^=btn-animated][class*=-to-orange]:hover, .btn[class*=" btn-animated"][class*=-to-orange]:hover {
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-orange]:before, .btn[class*=" btn-animated"][class*=-to-orange]:before {
  background-color: #ff9100;
}
.btn[class^=btn-animated][class*=-to-white]:hover, .btn[class*=" btn-animated"][class*=-to-white]:hover {
  color: #000000;
}
.btn[class^=btn-animated][class*=-to-white]:before, .btn[class*=" btn-animated"][class*=-to-white]:before {
  background-color: #fff;
}
.btn[class^=btn-animated][class*=-to-black]:hover, .btn[class*=" btn-animated"][class*=-to-black]:hover {
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-black]:before, .btn[class*=" btn-animated"][class*=-to-black]:before {
  background-color: #000000;
}
.btn[class^=btn-animated][class*=-to-gray]:hover, .btn[class*=" btn-animated"][class*=-to-gray]:hover {
  color: #000000;
}
.btn[class^=btn-animated][class*=-to-gray]:before, .btn[class*=" btn-animated"][class*=-to-gray]:before {
  background-color: #d4d4d4;
}
.btn[class^=btn-animated][class*=-to-darkgray]:hover, .btn[class*=" btn-animated"][class*=-to-darkgray]:hover {
  color: #fff;
}
.btn[class^=btn-animated][class*=-to-darkgray]:before, .btn[class*=" btn-animated"][class*=-to-darkgray]:before {
  background-color: #d4d4d4;
}

.table-container {
  overflow-x: auto;
}
.table-fw {
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit;
}
table td, table th {
  vertical-align: top;
  border: 1px solid #d4d4d4;
  padding: 5px;
}
table th {
  font-weight: 700;
  text-align: center;
}
table.text-left td, table.text-left th {
  text-align: left;
}
table.text-right td, table.text-right th {
  text-align: right;
}
table.text-center td, table.text-center th {
  text-align: center;
}
table.table-inverted {
  color: #f7f7f7;
}
table.table-valign-top td, table.table-valign-top th {
  vertical-align: top;
}
table.table-valign-middle td, table.table-valign-middle th {
  vertical-align: middle;
}
table.table-valign-bottom td, table.table-valign-bottom th {
  vertical-align: bottom;
}

xmp {
  font-size: 0.7rem;
  font-family: monospace;
}

.code-container {
  overflow-x: auto;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
body {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
body main {
  flex: 1 1 auto;
}
body.menu-opened main {
  display: none;
}

.qb-header {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  position: relative;
}
.qb-header a {
  text-decoration: none;
}
.qb-header a:hover, .qb-header a.active {
  color: #000000;
}
.qb-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.qb-header-logo-block a {
  display: block;
  padding: 15px 0;
}
.qb-header-logo-block a img {
  height: 50px;
}
.qb-header nav {
  font-size: 14px;
  z-index: 10;
}
.qb-header nav ul {
  display: flex;
  margin: 0;
}
.qb-header nav ul a {
  padding: 0.5rem;
}
@media screen and (max-width: 990px) {
  .qb-header nav {
    flex: 1 1 100%;
    display: none;
    margin-left: calc(-15px - 1%);
    margin-right: calc(-15px - 1%);
    width: 100%;
  }
  .qb-header nav ul {
    min-height: calc(100vh - 80px);
    flex-direction: column;
    justify-content: space-evenly;
  }
  .qb-header nav ul a {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
}
.qb-header-hamburger {
  backface-visibility: hidden;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.qb-header-hamburger:focus {
  outline: none;
}
@media screen and (min-width: 991px) {
  .qb-header-hamburger {
    display: none;
  }
}
.qb-header-hamburger svg {
  height: 52px;
  width: auto;
}
.qb-header-hamburger .line {
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.qb-header-hamburger .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.qb-header-hamburger .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.qb-header-hamburger .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.qb-header-hamburger.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.qb-header-hamburger.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.qb-header-hamburger.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.qb-footer {
  color: #fff;
  padding: 3rem 0;
}
.qb-footer a {
  text-decoration: none;
}
.qb-footer a:hover {
  text-decoration: underline;
}
.qb-footer-top, .qb-footer-bottom {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media screen and (min-width: 991px) {
  .qb-footer-top, .qb-footer-bottom {
    display: flex;
  }
}
.qb-footer-top {
  font-size: 11.4285714286px;
}
.qb-footer-top h4 {
  font-weight: 700;
}
.qb-footer-top h4 + p {
  color: #aaa;
}
@media screen and (min-width: 480px) {
  .qb-footer-top {
    font-size: calc(11.4285714286px + 4.5714285714 * (100vw - 480px) / 1440);
  }
}
@media screen and (min-width: 1920px) {
  .qb-footer-top {
    font-size: 16px;
  }
}
.qb-footer-bottom {
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #aaa;
}
.qb-footer-bottom ul {
  display: flex;
  justify-content: flex-end;
  font-size: 12.8571428571px;
}
@media screen and (min-width: 480px) {
  .qb-footer-bottom ul {
    font-size: calc(12.8571428571px + 5.1428571429 * (100vw - 480px) / 1440);
  }
}
@media screen and (min-width: 1920px) {
  .qb-footer-bottom ul {
    font-size: 18px;
  }
}
.qb-footer-bottom ul li {
  margin-right: 2rem;
}
.qb-footer-bottom ul li:last-child {
  margin-right: 0;
}
@media screen and (min-width: 991px) {
  .qb-footer-bottom h4 {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 990px) {
  .qb-footer-bottom ul {
    flex-direction: column;
  }
  .qb-footer-bottom ul li {
    margin-bottom: 0.5rem;
  }
}

form {
  position: relative;
}
form input.has-danger, form select.has-danger, form textarea.has-danger {
  border-bottom: 1px solid #f00 !important;
}
form .input-wrapper.has-danger {
  border-color: #f00 !important;
}
form .input-wrapper.has-danger:before, form .input-wrapper.has-danger:after {
  background-color: #f00 !important;
}
form input[type=radio].has-danger + label:before,
form input[type=checkbox].has-danger + label:before {
  border-color: #f00 !important;
}
form ::placeholder {
  color: #d4d4d4;
}
form input, form select, form textarea {
  font-size: 1rem;
  color: #292929;
  font-family: inherit;
  padding: 1em 2em;
}
form input, form textarea, form select, form .select-wrapper {
  width: 100%;
  border: 1px solid #d4d4d4;
}
form input {
  min-height: 3rem;
}
form .select-wrapper {
  min-height: 3rem;
  position: relative;
}
form .select-wrapper:before {
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5em;
  z-index: 10;
  color: #720067;
  pointer-events: none;
}
form .select-wrapper:after {
  position: absolute;
  display: block;
  content: "";
  right: 0;
  top: 0;
  width: 1.6em;
  height: 100%;
  font-size: 2em;
  z-index: 5;
  pointer-events: none;
}
form select {
  min-height: 3rem;
  width: 100%;
  border-radius: 0;
}
form select:disabled {
  color: #d4d4d4;
}
form select option [disabled=disabled] {
  color: #d4d4d4;
}
form p.question {
  font-size: calc(24px + 0.45vw);
}
@media screen and (min-width: 1919px) {
  form p.question {
    font-size: 33px;
  }
}
@media screen and (max-width: 479px) {
  form p.question {
    font-size: 24px;
  }
}
form p.c-info {
  letter-spacing: 0.1em;
  font-size: calc(16px + 0.4vw);
}
@media screen and (min-width: 1919px) {
  form p.c-info {
    font-size: 24px;
  }
}
@media screen and (max-width: 479px) {
  form p.c-info {
    font-size: 16px;
  }
}
form label {
  text-align: left;
  display: block;
  padding-bottom: 0.2em;
}
form label.file_upload {
  display: inline-flex;
  align-items: flex-start;
}
form label.file_upload input[type=file] {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  display: none;
}
form label.file_upload mark {
  background-color: transparent;
  color: #fff;
  opacity: 1;
  font-size: 1rem;
  margin-left: 0.5em;
  transition: all 0.3s ease-in-out;
}
form label.file_upload .btn {
  cursor: pointer;
}
form label.file_upload .btn.empty {
  color: #fff;
}
form label.file_upload .btn:not(.empty):hover + mark {
  opacity: 0;
}
form .row {
  padding-top: 0px;
  padding-bottom: 0px;
}
form .row .col.file-upload-container {
  text-align: left;
}
form .row .col.file-upload-container.has-danger {
  border: 1px solid #f00;
}
form .row .col.file-upload-container.has-danger mark {
  color: #f00;
}
@media screen and (max-width: 990px) {
  form .row .col {
    margin-bottom: 15px;
  }
}
form .checkbox input {
  display: none;
}
form .checkbox label {
  position: relative;
  padding-left: 4em;
  line-height: 3em;
  cursor: pointer;
}
form .checkbox label:before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 3em;
  height: 3em;
  background-color: #fff;
}
form .checkbox label:after {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 0.4em;
  content: "";
  color: #720067;
  font-size: calc(30px + 0.9vw);
}
@media screen and (min-width: 1919px) {
  form .checkbox label:after {
    font-size: 48px;
  }
}
@media screen and (max-width: 479px) {
  form .checkbox label:after {
    font-size: 30px;
  }
}
form .checkbox input:checked + label:after {
  content: "V";
}

.related-articles {
  background-color: #FAFAFA;
}
.related-articles a {
  text-decoration: none;
}
.related-articles-list {
  display: flex;
  flex-wrap: wrap;
}
.related-articles-list li {
  list-style-type: none;
  margin-right: 0;
  margin-left: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}
.related-articles-list li h4 {
  font-weight: 700;
  font-family: inherit;
  font-size: 12.8571428571px;
}
@media screen and (min-width: 480px) {
  .related-articles-list li h4 {
    font-size: calc(12.8571428571px + 5.1428571429 * (100vw - 480px) / 1440);
  }
}
@media screen and (min-width: 1920px) {
  .related-articles-list li h4 {
    font-size: 18px;
  }
}
@media screen and (min-width: 991px) {
  .related-articles-list li h4 {
    line-height: 20px;
  }
}
@media screen and (max-width: 990px) {
  .related-articles-list li h4 {
    line-height: inherit;
  }
}
.related-articles-list li p {
  font-size: 10.7142857143px;
}
@media screen and (min-width: 480px) {
  .related-articles-list li p {
    font-size: calc(10.7142857143px + 4.2857142857 * (100vw - 480px) / 1440);
  }
}
@media screen and (min-width: 1920px) {
  .related-articles-list li p {
    font-size: 15px;
  }
}
.related-articles-title {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(230, 230, 230);
  font-weight: 700;
  color: #292929;
}
.related-articles-img {
  flex: 0 0 auto;
}
.related-articles-img img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.related-articles-desc {
  margin-right: 1rem;
}
@media screen and (min-width: 1250px) {
  .related-articles-list li {
    margin-right: 5%;
    flex: 0 0 20%;
  }
  .related-articles-list li:nth-child(4n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 991px) and (max-width: 1249px) {
  .related-articles-list li {
    margin-right: 5%;
    flex: 1 0 28%;
  }
  .related-articles-list li:nth-child(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 990px) {
  .related-articles-list li {
    justify-content: space-between;
    flex: 1 0 100%;
    margin-right: 0;
  }
}

.tagline ul {
  display: flex;
  flex-wrap: wrap;
}
.tagline ul li {
  list-style-type: none;
  margin-left: 0;
  margin-right: 0.5rem;
}
.tagline ul li a {
  background-color: #f2f2f2;
  padding: 0.25rem 0.5rem;
  color: #757575;
  font-size: 15px;
  text-decoration: none;
}
@media screen and (max-width: 990px) {
  .tagline ul {
    flex-direction: column;
  }
  .tagline ul li {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
  .tagline ul li a {
    width: 100%;
    text-align: center;
  }
}

.articles-hub .row {
  flex-wrap: wrap;
}
@media screen and (min-width: 991px) {
  .articles-hub .row .col {
    flex: 0 0 23%;
    margin: 0 1% 2%;
    max-width: 23%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .articles-hub .row {
    display: flex;
  }
  .articles-hub .row .col {
    flex: 0 0 48%;
    margin: 0 1% 2%;
    max-width: 48%;
  }
}
@media screen and (max-width: 990px) {
  .articles-hub .row {
    margin-left: 0;
    margin-right: 0;
  }
}
.articles-hub .col {
  position: relative;
  padding-bottom: calc(3rem + 15px);
  padding-top: 15px;
  border: 2px solid #f7f7f7;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
}
.articles-hub .col .btn {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
.articles-hub img {
  display: block;
  margin-bottom: 1rem;
}
.articles-hub h4 {
  font-size: 21.4285714286px;
}
@media screen and (min-width: 480px) {
  .articles-hub h4 {
    font-size: calc(21.4285714286px + 8.5714285714 * (100vw - 480px) / 1440);
  }
}
@media screen and (min-width: 1920px) {
  .articles-hub h4 {
    font-size: 30px;
  }
}
.articles-hub .excerpt {
  font-size: 0.8rem;
}

#canvasDisplay {
  position: relative;
}
#canvasDisplay input[type=range] {
  left: 50% !important;
  top: 10px !important;
  transform: translateX(-50%);
}

.m-0 {
  margin: 0rem;
}

.p-0 {
  padding: 0rem;
}

.m-1 {
  margin: 1rem;
}

.p-1 {
  padding: 1rem;
}

.m-2 {
  margin: 2rem;
}

.p-2 {
  padding: 2rem;
}

.m-3 {
  margin: 3rem;
}

.p-3 {
  padding: 3rem;
}

.m-4 {
  margin: 4rem;
}

.p-4 {
  padding: 4rem;
}

.m-5 {
  margin: 5rem;
}

.p-5 {
  padding: 5rem;
}

.m-6 {
  margin: 6rem;
}

.p-6 {
  padding: 6rem;
}

.m-7 {
  margin: 7rem;
}

.p-7 {
  padding: 7rem;
}

.m-8 {
  margin: 8rem;
}

.p-8 {
  padding: 8rem;
}

.m-9 {
  margin: 9rem;
}

.p-9 {
  padding: 9rem;
}

.m-10 {
  margin: 10rem;
}

.p-10 {
  padding: 10rem;
}

.mv-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.pv-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.mv-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pv-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.mv-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pv-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.mv-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.pv-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.mv-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.pv-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.mv-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.pv-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.mv-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.pv-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.mv-7 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.pv-7 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.mv-8 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.pv-8 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.mv-9 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.pv-9 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.mv-10 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.pv-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.ms-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.ps-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.ms-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.ps-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.ms-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.ps-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.ms-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.ps-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.ms-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.ps-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.ms-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.ps-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.ms-6 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.ps-6 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.ms-7 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.ps-7 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.ms-8 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.ps-8 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.ms-9 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.ps-9 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.ms-10 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.ps-10 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.mt-0 {
  margin-top: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.ml-0 {
  margin-left: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

.pt-0 {
  padding-top: 0rem;
}

.pb-0 {
  padding-bottom: 0rem;
}

.pl-0 {
  padding-left: 0rem;
}

.pr-0 {
  padding-right: 0rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.ml-3 {
  margin-left: 3rem;
}

.mr-3 {
  margin-right: 3rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pr-3 {
  padding-right: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.ml-4 {
  margin-left: 4rem;
}

.mr-4 {
  margin-right: 4rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pl-4 {
  padding-left: 4rem;
}

.pr-4 {
  padding-right: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mr-5 {
  margin-right: 5rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pl-5 {
  padding-left: 5rem;
}

.pr-5 {
  padding-right: 5rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.ml-6 {
  margin-left: 6rem;
}

.mr-6 {
  margin-right: 6rem;
}

.pt-6 {
  padding-top: 6rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.pl-6 {
  padding-left: 6rem;
}

.pr-6 {
  padding-right: 6rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mb-7 {
  margin-bottom: 7rem;
}

.ml-7 {
  margin-left: 7rem;
}

.mr-7 {
  margin-right: 7rem;
}

.pt-7 {
  padding-top: 7rem;
}

.pb-7 {
  padding-bottom: 7rem;
}

.pl-7 {
  padding-left: 7rem;
}

.pr-7 {
  padding-right: 7rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mb-8 {
  margin-bottom: 8rem;
}

.ml-8 {
  margin-left: 8rem;
}

.mr-8 {
  margin-right: 8rem;
}

.pt-8 {
  padding-top: 8rem;
}

.pb-8 {
  padding-bottom: 8rem;
}

.pl-8 {
  padding-left: 8rem;
}

.pr-8 {
  padding-right: 8rem;
}

.mt-9 {
  margin-top: 9rem;
}

.mb-9 {
  margin-bottom: 9rem;
}

.ml-9 {
  margin-left: 9rem;
}

.mr-9 {
  margin-right: 9rem;
}

.pt-9 {
  padding-top: 9rem;
}

.pb-9 {
  padding-bottom: 9rem;
}

.pl-9 {
  padding-left: 9rem;
}

.pr-9 {
  padding-right: 9rem;
}

.mt-10 {
  margin-top: 10rem;
}

.mb-10 {
  margin-bottom: 10rem;
}

.ml-10 {
  margin-left: 10rem;
}

.mr-10 {
  margin-right: 10rem;
}

.pt-10 {
  padding-top: 10rem;
}

.pb-10 {
  padding-bottom: 10rem;
}

.pl-10 {
  padding-left: 10rem;
}

.pr-10 {
  padding-right: 10rem;
}

.m-05 {
  margin: 0.5rem;
}
.ms-05 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mv-05 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mr-05 {
  margin-right: 0.5rem;
}
.ml-05 {
  margin-left: 0.5rem;
}
.mt-05 {
  margin-top: 0.5rem;
}
.mb-05 {
  margin-bottom: 0.5rem;
}

.p-05 {
  padding: 0.5rem;
}
.ps-05 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.pv-05 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pr-05 {
  padding-right: 0.5rem;
}
.pl-05 {
  padding-left: 0.5rem;
}
.pt-05 {
  padding-top: 0.5rem;
}
.pb-05 {
  padding-bottom: 0.5rem;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-0 {
    margin: 0;
  }
  .ms-mh-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .mv-mh-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .mt-mh-0 {
    margin-top: 0;
  }
  .mb-mh-0 {
    margin-bottom: 0;
  }
  .ml-mh-0 {
    margin-left: 0;
  }
  .mr-mh-0 {
    margin-right: 0;
  }
  .p-mh-0 {
    padding: 0;
  }
  .ps-mh-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .pv-mh-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .pt-mh-0 {
    padding-top: 0;
  }
  .pb-mh-0 {
    padding-bottom: 0;
  }
  .pl-mh-0 {
    padding-left: 0;
  }
  .pr-mh-00 {
    padding-right: 0;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-0 {
    margin: 0;
  }
  .mt-mv-0 {
    margin-top: 0;
  }
  .mb-mv-0 {
    margin-bottom: 0;
  }
  .ml-mv-0 {
    margin-left: 0;
  }
  .mr-mv-0 {
    margin-right: 0;
  }
  .ms-mv-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .mv-mv-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .pt-mv-0 {
    padding-top: 0;
  }
  .pb-mv-0 {
    padding-bottom: 0;
  }
  .pl-mv-0 {
    padding-left: 0;
  }
  .pr-mv-0 {
    padding-right: 0;
  }
  .ps-mv-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .pv-mv-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .p-mv-0 {
    padding: 0;
  }
}
@media screen and (min-width: 991px) {
  .m-dt-0 {
    margin: 0;
  }
  .ml-dt-0 {
    margin-left: 0;
  }
  .mr-dt-0 {
    margin-right: 0;
  }
  .mt-dt-0 {
    margin-top: 0;
  }
  .mb-dt-0 {
    margin-bottom: 0;
  }
  .mv-dt-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .ms-dt-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .p-dt-0 {
    padding: 0;
  }
  .pl-dt-0 {
    padding-left: 0;
  }
  .pr-dt-0 {
    padding-right: 0;
  }
  .pt-dt-0 {
    padding-top: 0;
  }
  .pb-dt-0 {
    padding-bottom: 0;
  }
  .pv-dt-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ps-dt-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-0 {
    margin: 0;
  }
  .ml-ls-0 {
    margin-left: 0;
  }
  .mr-ls-0 {
    margin-right: 0;
  }
  .mt-ls-0 {
    margin-top: 0;
  }
  .mb-ls-0 {
    margin-bottom: 0;
  }
  .mv-ls-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .ms-ls-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .p-ls-0 {
    padding: 0;
  }
  .pl-ls-0 {
    padding-left: 0;
  }
  .pr-ls-0 {
    padding-right: 0;
  }
  .pt-ls-0 {
    padding-top: 0;
  }
  .pb-ls-0 {
    padding-bottom: 0;
  }
  .pv-ls-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ps-ls-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-05 {
    margin: 0.5rem;
  }
  .ms-mh-05 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .mv-mh-05 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .mr-mh-05 {
    margin-right: 0.5rem;
  }
  .ml-mh-05 {
    margin-left: 0.5rem;
  }
  .mt-mh-05 {
    margin-top: 0.5rem;
  }
  .mb-mh-05 {
    margin-bottom: 0.5rem;
  }
  .p-mh-05 {
    padding: 0.5rem;
  }
  .ps-mh-05 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .pv-mh-05 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .pr-mh-05 {
    padding-right: 0.5rem;
  }
  .pl-mh-05 {
    padding-left: 0.5rem;
  }
  .pt-mh-05 {
    padding-top: 0.5rem;
  }
  .pb-mh-05 {
    padding-bottom: 0.5rem;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-05 {
    margin: 0.5rem;
  }
  .ms-mv-05 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .mv-mv-05 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .mr-mv-05 {
    margin-right: 0.5rem;
  }
  .ml-mv-05 {
    margin-left: 0.5rem;
  }
  .mt-mv-05 {
    margin-top: 0.5rem;
  }
  .mb-mv-05 {
    margin-bottom: 0.5rem;
  }
  .p-mv-05 {
    padding: 0.5rem;
  }
  .ps-mv-05 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .pv-mv-05 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .pr-mv-05 {
    padding-right: 0.5rem;
  }
  .pl-mv-05 {
    padding-left: 0.5rem;
  }
  .pt-mv-05 {
    padding-top: 0.5rem;
  }
  .pb-mv-05 {
    padding-bottom: 0.5rem;
  }
}
@media screen and (min-width: 991px) {
  .m-dt-05 {
    margin: 0.5rem;
  }
  .ms-dt-05 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .mv-dt-05 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .mr-dt-05 {
    margin-right: 0.5rem;
  }
  .ml-dt-05 {
    margin-left: 0.5rem;
  }
  .mt-dt-05 {
    margin-top: 0.5rem;
  }
  .mb-dt-05 {
    margin-bottom: 0.5rem;
  }
  .p-dt-05 {
    padding: 0.5rem;
  }
  .ps-dt-05 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .pv-dt-05 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .pr-dt-05 {
    padding-right: 0.5rem;
  }
  .pl-dt-05 {
    padding-left: 0.5rem;
  }
  .pt-dt-05 {
    padding-top: 0.5rem;
  }
  .pb-dt-05 {
    padding-bottom: 0.5rem;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-05 {
    margin: 0.5rem;
  }
  .ms-ls-05 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .mv-ls-05 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .mr-ls-05 {
    margin-right: 0.5rem;
  }
  .ml-ls-05 {
    margin-left: 0.5rem;
  }
  .mt-ls-05 {
    margin-top: 0.5rem;
  }
  .mb-ls-05 {
    margin-bottom: 0.5rem;
  }
  .p-ls-05 {
    padding: 0.5rem;
  }
  .ps-ls-05 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .pv-ls-05 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .pr-ls-05 {
    padding-right: 0.5rem;
  }
  .pl-ls-05 {
    padding-left: 0.5rem;
  }
  .pt-ls-05 {
    padding-top: 0.5rem;
  }
  .pb-ls-05 {
    padding-bottom: 0.5rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-1 {
    margin: 1rem;
  }
  .p-mh-1 {
    padding: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-1 {
    margin: 1rem;
  }
  .p-mv-1 {
    padding: 1rem;
  }
}
@media screen and (min-width: 991px) {
  .m-dt-1 {
    margin: 1rem;
  }
  .p-dt-1 {
    padding: 1rem;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-1 {
    margin: 1rem;
  }
  .p-ls-1 {
    padding: 1rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-2 {
    margin: 2rem;
  }
  .p-mh-2 {
    padding: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-2 {
    margin: 2rem;
  }
  .p-mv-2 {
    padding: 2rem;
  }
}
@media screen and (min-width: 991px) {
  .m-dt-2 {
    margin: 2rem;
  }
  .p-dt-2 {
    padding: 2rem;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-2 {
    margin: 2rem;
  }
  .p-ls-2 {
    padding: 2rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-3 {
    margin: 3rem;
  }
  .p-mh-3 {
    padding: 3rem;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-3 {
    margin: 3rem;
  }
  .p-mv-3 {
    padding: 3rem;
  }
}
@media screen and (min-width: 991px) {
  .m-dt-3 {
    margin: 3rem;
  }
  .p-dt-3 {
    padding: 3rem;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-3 {
    margin: 3rem;
  }
  .p-ls-3 {
    padding: 3rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-4 {
    margin: 4rem;
  }
  .p-mh-4 {
    padding: 4rem;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-4 {
    margin: 4rem;
  }
  .p-mv-4 {
    padding: 4rem;
  }
}
@media screen and (min-width: 991px) {
  .m-dt-4 {
    margin: 4rem;
  }
  .p-dt-4 {
    padding: 4rem;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-4 {
    margin: 4rem;
  }
  .p-ls-4 {
    padding: 4rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-5 {
    margin: 5rem;
  }
  .p-mh-5 {
    padding: 5rem;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-5 {
    margin: 5rem;
  }
  .p-mv-5 {
    padding: 5rem;
  }
}
@media screen and (min-width: 991px) {
  .m-dt-5 {
    margin: 5rem;
  }
  .p-dt-5 {
    padding: 5rem;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-5 {
    margin: 5rem;
  }
  .p-ls-5 {
    padding: 5rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-6 {
    margin: 6rem;
  }
  .p-mh-6 {
    padding: 6rem;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-6 {
    margin: 6rem;
  }
  .p-mv-6 {
    padding: 6rem;
  }
}
@media screen and (min-width: 991px) {
  .m-dt-6 {
    margin: 6rem;
  }
  .p-dt-6 {
    padding: 6rem;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-6 {
    margin: 6rem;
  }
  .p-ls-6 {
    padding: 6rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-7 {
    margin: 7rem;
  }
  .p-mh-7 {
    padding: 7rem;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-7 {
    margin: 7rem;
  }
  .p-mv-7 {
    padding: 7rem;
  }
}
@media screen and (min-width: 991px) {
  .m-dt-7 {
    margin: 7rem;
  }
  .p-dt-7 {
    padding: 7rem;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-7 {
    margin: 7rem;
  }
  .p-ls-7 {
    padding: 7rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-8 {
    margin: 8rem;
  }
  .p-mh-8 {
    padding: 8rem;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-8 {
    margin: 8rem;
  }
  .p-mv-8 {
    padding: 8rem;
  }
}
@media screen and (min-width: 991px) {
  .m-dt-8 {
    margin: 8rem;
  }
  .p-dt-8 {
    padding: 8rem;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-8 {
    margin: 8rem;
  }
  .p-ls-8 {
    padding: 8rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-9 {
    margin: 9rem;
  }
  .p-mh-9 {
    padding: 9rem;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-9 {
    margin: 9rem;
  }
  .p-mv-9 {
    padding: 9rem;
  }
}
@media screen and (min-width: 991px) {
  .m-dt-9 {
    margin: 9rem;
  }
  .p-dt-9 {
    padding: 9rem;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-9 {
    margin: 9rem;
  }
  .p-ls-9 {
    padding: 9rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-10 {
    margin: 10rem;
  }
  .p-mh-10 {
    padding: 10rem;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-10 {
    margin: 10rem;
  }
  .p-mv-10 {
    padding: 10rem;
  }
}
@media screen and (min-width: 991px) {
  .m-dt-10 {
    margin: 10rem;
  }
  .p-dt-10 {
    padding: 10rem;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-10 {
    margin: 10rem;
  }
  .p-ls-10 {
    padding: 10rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mv-mh-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pv-mh-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .ms-mh-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .ps-mh-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .mv-mv-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pv-mv-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .ms-mv-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .ps-mv-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (min-width: 991px) {
  .mv-dt-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pv-dt-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .ms-dt-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .ps-dt-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (min-width: 2430px) {
  .mv-ls-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pv-ls-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .ms-ls-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .ps-ls-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mv-mh-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pv-mh-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .ms-mh-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .ps-mh-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .mv-mv-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pv-mv-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .ms-mv-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .ps-mv-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (min-width: 991px) {
  .mv-dt-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pv-dt-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .ms-dt-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .ps-dt-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (min-width: 2430px) {
  .mv-ls-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pv-ls-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .ms-ls-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .ps-ls-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mv-mh-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pv-mh-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .ms-mh-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .ps-mh-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media screen and (max-width: 479px) {
  .mv-mv-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pv-mv-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .ms-mv-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .ps-mv-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media screen and (min-width: 991px) {
  .mv-dt-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pv-dt-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .ms-dt-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .ps-dt-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media screen and (min-width: 2430px) {
  .mv-ls-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pv-ls-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .ms-ls-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .ps-ls-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mv-mh-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pv-mh-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .ms-mh-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .ps-mh-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media screen and (max-width: 479px) {
  .mv-mv-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pv-mv-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .ms-mv-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .ps-mv-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media screen and (min-width: 991px) {
  .mv-dt-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pv-dt-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .ms-dt-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .ps-dt-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media screen and (min-width: 2430px) {
  .mv-ls-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pv-ls-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .ms-ls-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .ps-ls-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mv-mh-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pv-mh-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .ms-mh-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .ps-mh-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
@media screen and (max-width: 479px) {
  .mv-mv-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pv-mv-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .ms-mv-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .ps-mv-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
@media screen and (min-width: 991px) {
  .mv-dt-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pv-dt-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .ms-dt-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .ps-dt-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
@media screen and (min-width: 2430px) {
  .mv-ls-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pv-ls-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .ms-ls-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .ps-ls-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mv-mh-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pv-mh-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .ms-mh-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .ps-mh-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
@media screen and (max-width: 479px) {
  .mv-mv-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pv-mv-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .ms-mv-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .ps-mv-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
@media screen and (min-width: 991px) {
  .mv-dt-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pv-dt-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .ms-dt-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .ps-dt-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
@media screen and (min-width: 2430px) {
  .mv-ls-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pv-ls-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .ms-ls-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .ps-ls-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mv-mh-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pv-mh-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .ms-mh-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .ps-mh-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}
@media screen and (max-width: 479px) {
  .mv-mv-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pv-mv-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .ms-mv-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .ps-mv-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}
@media screen and (min-width: 991px) {
  .mv-dt-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pv-dt-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .ms-dt-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .ps-dt-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}
@media screen and (min-width: 2430px) {
  .mv-ls-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pv-ls-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .ms-ls-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .ps-ls-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mv-mh-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pv-mh-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .ms-mh-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .ps-mh-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}
@media screen and (max-width: 479px) {
  .mv-mv-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pv-mv-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .ms-mv-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .ps-mv-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}
@media screen and (min-width: 991px) {
  .mv-dt-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pv-dt-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .ms-dt-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .ps-dt-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}
@media screen and (min-width: 2430px) {
  .mv-ls-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pv-ls-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .ms-ls-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .ps-ls-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mv-mh-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pv-mh-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .ms-mh-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .ps-mh-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
}
@media screen and (max-width: 479px) {
  .mv-mv-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pv-mv-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .ms-mv-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .ps-mv-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
}
@media screen and (min-width: 991px) {
  .mv-dt-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pv-dt-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .ms-dt-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .ps-dt-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
}
@media screen and (min-width: 2430px) {
  .mv-ls-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pv-ls-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .ms-ls-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .ps-ls-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mv-mh-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pv-mh-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .ms-mh-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .ps-mh-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}
@media screen and (max-width: 479px) {
  .mv-mv-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pv-mv-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .ms-mv-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .ps-mv-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}
@media screen and (min-width: 991px) {
  .mv-dt-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pv-dt-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .ms-dt-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .ps-dt-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}
@media screen and (min-width: 2430px) {
  .mv-ls-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pv-ls-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .ms-ls-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .ps-ls-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mt-mh-1 {
    margin-top: 1rem;
  }
  .mb-mh-1 {
    margin-bottom: 1rem;
  }
  .ml-mh-1 {
    margin-left: 1rem;
  }
  .mr-mh-1 {
    margin-right: 1rem;
  }
  .pt-mh-1 {
    padding-top: 1rem;
  }
  .pb-mh-1 {
    padding-bottom: 1rem;
  }
  .pl-mh-1 {
    padding-left: 1rem;
  }
  .pr-mh-1 {
    padding-right: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .mt-mv-1 {
    margin-top: 1rem;
  }
  .mb-mv-1 {
    margin-bottom: 1rem;
  }
  .ml-mv-1 {
    margin-left: 1rem;
  }
  .mr-mv-1 {
    margin-right: 1rem;
  }
  .pt-mv-1 {
    padding-top: 1rem;
  }
  .pb-mv-1 {
    padding-bottom: 1rem;
  }
  .pl-mv-1 {
    padding-left: 1rem;
  }
  .pr-mv-1 {
    padding-right: 1rem;
  }
}
@media screen and (min-width: 991px) {
  .ml-dt-1 {
    margin-left: 1rem;
  }
  .mr-dt-1 {
    margin-right: 1rem;
  }
  .mt-dt-1 {
    margin-top: 1rem;
  }
  .mb-dt-1 {
    margin-bottom: 1rem;
  }
  .pl-dt-1 {
    padding-left: 1rem;
  }
  .pr-dt-1 {
    padding-right: 1rem;
  }
  .pt-dt-1 {
    padding-top: 1rem;
  }
  .pb-dt-1 {
    padding-bottom: 1rem;
  }
}
@media screen and (min-width: 2430px) {
  .ml-ls-1 {
    margin-left: 1rem;
  }
  .mr-ls-1 {
    margin-right: 1rem;
  }
  .mt-ls-1 {
    margin-top: 1rem;
  }
  .mb-ls-1 {
    margin-bottom: 1rem;
  }
  .pl-ls-1 {
    padding-left: 1rem;
  }
  .pr-ls-1 {
    padding-right: 1rem;
  }
  .pt-ls-1 {
    padding-top: 1rem;
  }
  .pb-ls-1 {
    padding-bottom: 1rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mt-mh-2 {
    margin-top: 2rem;
  }
  .mb-mh-2 {
    margin-bottom: 2rem;
  }
  .ml-mh-2 {
    margin-left: 2rem;
  }
  .mr-mh-2 {
    margin-right: 2rem;
  }
  .pt-mh-2 {
    padding-top: 2rem;
  }
  .pb-mh-2 {
    padding-bottom: 2rem;
  }
  .pl-mh-2 {
    padding-left: 2rem;
  }
  .pr-mh-2 {
    padding-right: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .mt-mv-2 {
    margin-top: 2rem;
  }
  .mb-mv-2 {
    margin-bottom: 2rem;
  }
  .ml-mv-2 {
    margin-left: 2rem;
  }
  .mr-mv-2 {
    margin-right: 2rem;
  }
  .pt-mv-2 {
    padding-top: 2rem;
  }
  .pb-mv-2 {
    padding-bottom: 2rem;
  }
  .pl-mv-2 {
    padding-left: 2rem;
  }
  .pr-mv-2 {
    padding-right: 2rem;
  }
}
@media screen and (min-width: 991px) {
  .ml-dt-2 {
    margin-left: 2rem;
  }
  .mr-dt-2 {
    margin-right: 2rem;
  }
  .mt-dt-2 {
    margin-top: 2rem;
  }
  .mb-dt-2 {
    margin-bottom: 2rem;
  }
  .pl-dt-2 {
    padding-left: 2rem;
  }
  .pr-dt-2 {
    padding-right: 2rem;
  }
  .pt-dt-2 {
    padding-top: 2rem;
  }
  .pb-dt-2 {
    padding-bottom: 2rem;
  }
}
@media screen and (min-width: 2430px) {
  .ml-ls-2 {
    margin-left: 2rem;
  }
  .mr-ls-2 {
    margin-right: 2rem;
  }
  .mt-ls-2 {
    margin-top: 2rem;
  }
  .mb-ls-2 {
    margin-bottom: 2rem;
  }
  .pl-ls-2 {
    padding-left: 2rem;
  }
  .pr-ls-2 {
    padding-right: 2rem;
  }
  .pt-ls-2 {
    padding-top: 2rem;
  }
  .pb-ls-2 {
    padding-bottom: 2rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mt-mh-3 {
    margin-top: 3rem;
  }
  .mb-mh-3 {
    margin-bottom: 3rem;
  }
  .ml-mh-3 {
    margin-left: 3rem;
  }
  .mr-mh-3 {
    margin-right: 3rem;
  }
  .pt-mh-3 {
    padding-top: 3rem;
  }
  .pb-mh-3 {
    padding-bottom: 3rem;
  }
  .pl-mh-3 {
    padding-left: 3rem;
  }
  .pr-mh-3 {
    padding-right: 3rem;
  }
}
@media screen and (max-width: 479px) {
  .mt-mv-3 {
    margin-top: 3rem;
  }
  .mb-mv-3 {
    margin-bottom: 3rem;
  }
  .ml-mv-3 {
    margin-left: 3rem;
  }
  .mr-mv-3 {
    margin-right: 3rem;
  }
  .pt-mv-3 {
    padding-top: 3rem;
  }
  .pb-mv-3 {
    padding-bottom: 3rem;
  }
  .pl-mv-3 {
    padding-left: 3rem;
  }
  .pr-mv-3 {
    padding-right: 3rem;
  }
}
@media screen and (min-width: 991px) {
  .ml-dt-3 {
    margin-left: 3rem;
  }
  .mr-dt-3 {
    margin-right: 3rem;
  }
  .mt-dt-3 {
    margin-top: 3rem;
  }
  .mb-dt-3 {
    margin-bottom: 3rem;
  }
  .pl-dt-3 {
    padding-left: 3rem;
  }
  .pr-dt-3 {
    padding-right: 3rem;
  }
  .pt-dt-3 {
    padding-top: 3rem;
  }
  .pb-dt-3 {
    padding-bottom: 3rem;
  }
}
@media screen and (min-width: 2430px) {
  .ml-ls-3 {
    margin-left: 3rem;
  }
  .mr-ls-3 {
    margin-right: 3rem;
  }
  .mt-ls-3 {
    margin-top: 3rem;
  }
  .mb-ls-3 {
    margin-bottom: 3rem;
  }
  .pl-ls-3 {
    padding-left: 3rem;
  }
  .pr-ls-3 {
    padding-right: 3rem;
  }
  .pt-ls-3 {
    padding-top: 3rem;
  }
  .pb-ls-3 {
    padding-bottom: 3rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mt-mh-4 {
    margin-top: 4rem;
  }
  .mb-mh-4 {
    margin-bottom: 4rem;
  }
  .ml-mh-4 {
    margin-left: 4rem;
  }
  .mr-mh-4 {
    margin-right: 4rem;
  }
  .pt-mh-4 {
    padding-top: 4rem;
  }
  .pb-mh-4 {
    padding-bottom: 4rem;
  }
  .pl-mh-4 {
    padding-left: 4rem;
  }
  .pr-mh-4 {
    padding-right: 4rem;
  }
}
@media screen and (max-width: 479px) {
  .mt-mv-4 {
    margin-top: 4rem;
  }
  .mb-mv-4 {
    margin-bottom: 4rem;
  }
  .ml-mv-4 {
    margin-left: 4rem;
  }
  .mr-mv-4 {
    margin-right: 4rem;
  }
  .pt-mv-4 {
    padding-top: 4rem;
  }
  .pb-mv-4 {
    padding-bottom: 4rem;
  }
  .pl-mv-4 {
    padding-left: 4rem;
  }
  .pr-mv-4 {
    padding-right: 4rem;
  }
}
@media screen and (min-width: 991px) {
  .ml-dt-4 {
    margin-left: 4rem;
  }
  .mr-dt-4 {
    margin-right: 4rem;
  }
  .mt-dt-4 {
    margin-top: 4rem;
  }
  .mb-dt-4 {
    margin-bottom: 4rem;
  }
  .pl-dt-4 {
    padding-left: 4rem;
  }
  .pr-dt-4 {
    padding-right: 4rem;
  }
  .pt-dt-4 {
    padding-top: 4rem;
  }
  .pb-dt-4 {
    padding-bottom: 4rem;
  }
}
@media screen and (min-width: 2430px) {
  .ml-ls-4 {
    margin-left: 4rem;
  }
  .mr-ls-4 {
    margin-right: 4rem;
  }
  .mt-ls-4 {
    margin-top: 4rem;
  }
  .mb-ls-4 {
    margin-bottom: 4rem;
  }
  .pl-ls-4 {
    padding-left: 4rem;
  }
  .pr-ls-4 {
    padding-right: 4rem;
  }
  .pt-ls-4 {
    padding-top: 4rem;
  }
  .pb-ls-4 {
    padding-bottom: 4rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mt-mh-5 {
    margin-top: 5rem;
  }
  .mb-mh-5 {
    margin-bottom: 5rem;
  }
  .ml-mh-5 {
    margin-left: 5rem;
  }
  .mr-mh-5 {
    margin-right: 5rem;
  }
  .pt-mh-5 {
    padding-top: 5rem;
  }
  .pb-mh-5 {
    padding-bottom: 5rem;
  }
  .pl-mh-5 {
    padding-left: 5rem;
  }
  .pr-mh-5 {
    padding-right: 5rem;
  }
}
@media screen and (max-width: 479px) {
  .mt-mv-5 {
    margin-top: 5rem;
  }
  .mb-mv-5 {
    margin-bottom: 5rem;
  }
  .ml-mv-5 {
    margin-left: 5rem;
  }
  .mr-mv-5 {
    margin-right: 5rem;
  }
  .pt-mv-5 {
    padding-top: 5rem;
  }
  .pb-mv-5 {
    padding-bottom: 5rem;
  }
  .pl-mv-5 {
    padding-left: 5rem;
  }
  .pr-mv-5 {
    padding-right: 5rem;
  }
}
@media screen and (min-width: 991px) {
  .ml-dt-5 {
    margin-left: 5rem;
  }
  .mr-dt-5 {
    margin-right: 5rem;
  }
  .mt-dt-5 {
    margin-top: 5rem;
  }
  .mb-dt-5 {
    margin-bottom: 5rem;
  }
  .pl-dt-5 {
    padding-left: 5rem;
  }
  .pr-dt-5 {
    padding-right: 5rem;
  }
  .pt-dt-5 {
    padding-top: 5rem;
  }
  .pb-dt-5 {
    padding-bottom: 5rem;
  }
}
@media screen and (min-width: 2430px) {
  .ml-ls-5 {
    margin-left: 5rem;
  }
  .mr-ls-5 {
    margin-right: 5rem;
  }
  .mt-ls-5 {
    margin-top: 5rem;
  }
  .mb-ls-5 {
    margin-bottom: 5rem;
  }
  .pl-ls-5 {
    padding-left: 5rem;
  }
  .pr-ls-5 {
    padding-right: 5rem;
  }
  .pt-ls-5 {
    padding-top: 5rem;
  }
  .pb-ls-5 {
    padding-bottom: 5rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mt-mh-6 {
    margin-top: 6rem;
  }
  .mb-mh-6 {
    margin-bottom: 6rem;
  }
  .ml-mh-6 {
    margin-left: 6rem;
  }
  .mr-mh-6 {
    margin-right: 6rem;
  }
  .pt-mh-6 {
    padding-top: 6rem;
  }
  .pb-mh-6 {
    padding-bottom: 6rem;
  }
  .pl-mh-6 {
    padding-left: 6rem;
  }
  .pr-mh-6 {
    padding-right: 6rem;
  }
}
@media screen and (max-width: 479px) {
  .mt-mv-6 {
    margin-top: 6rem;
  }
  .mb-mv-6 {
    margin-bottom: 6rem;
  }
  .ml-mv-6 {
    margin-left: 6rem;
  }
  .mr-mv-6 {
    margin-right: 6rem;
  }
  .pt-mv-6 {
    padding-top: 6rem;
  }
  .pb-mv-6 {
    padding-bottom: 6rem;
  }
  .pl-mv-6 {
    padding-left: 6rem;
  }
  .pr-mv-6 {
    padding-right: 6rem;
  }
}
@media screen and (min-width: 991px) {
  .ml-dt-6 {
    margin-left: 6rem;
  }
  .mr-dt-6 {
    margin-right: 6rem;
  }
  .mt-dt-6 {
    margin-top: 6rem;
  }
  .mb-dt-6 {
    margin-bottom: 6rem;
  }
  .pl-dt-6 {
    padding-left: 6rem;
  }
  .pr-dt-6 {
    padding-right: 6rem;
  }
  .pt-dt-6 {
    padding-top: 6rem;
  }
  .pb-dt-6 {
    padding-bottom: 6rem;
  }
}
@media screen and (min-width: 2430px) {
  .ml-ls-6 {
    margin-left: 6rem;
  }
  .mr-ls-6 {
    margin-right: 6rem;
  }
  .mt-ls-6 {
    margin-top: 6rem;
  }
  .mb-ls-6 {
    margin-bottom: 6rem;
  }
  .pl-ls-6 {
    padding-left: 6rem;
  }
  .pr-ls-6 {
    padding-right: 6rem;
  }
  .pt-ls-6 {
    padding-top: 6rem;
  }
  .pb-ls-6 {
    padding-bottom: 6rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mt-mh-7 {
    margin-top: 7rem;
  }
  .mb-mh-7 {
    margin-bottom: 7rem;
  }
  .ml-mh-7 {
    margin-left: 7rem;
  }
  .mr-mh-7 {
    margin-right: 7rem;
  }
  .pt-mh-7 {
    padding-top: 7rem;
  }
  .pb-mh-7 {
    padding-bottom: 7rem;
  }
  .pl-mh-7 {
    padding-left: 7rem;
  }
  .pr-mh-7 {
    padding-right: 7rem;
  }
}
@media screen and (max-width: 479px) {
  .mt-mv-7 {
    margin-top: 7rem;
  }
  .mb-mv-7 {
    margin-bottom: 7rem;
  }
  .ml-mv-7 {
    margin-left: 7rem;
  }
  .mr-mv-7 {
    margin-right: 7rem;
  }
  .pt-mv-7 {
    padding-top: 7rem;
  }
  .pb-mv-7 {
    padding-bottom: 7rem;
  }
  .pl-mv-7 {
    padding-left: 7rem;
  }
  .pr-mv-7 {
    padding-right: 7rem;
  }
}
@media screen and (min-width: 991px) {
  .ml-dt-7 {
    margin-left: 7rem;
  }
  .mr-dt-7 {
    margin-right: 7rem;
  }
  .mt-dt-7 {
    margin-top: 7rem;
  }
  .mb-dt-7 {
    margin-bottom: 7rem;
  }
  .pl-dt-7 {
    padding-left: 7rem;
  }
  .pr-dt-7 {
    padding-right: 7rem;
  }
  .pt-dt-7 {
    padding-top: 7rem;
  }
  .pb-dt-7 {
    padding-bottom: 7rem;
  }
}
@media screen and (min-width: 2430px) {
  .ml-ls-7 {
    margin-left: 7rem;
  }
  .mr-ls-7 {
    margin-right: 7rem;
  }
  .mt-ls-7 {
    margin-top: 7rem;
  }
  .mb-ls-7 {
    margin-bottom: 7rem;
  }
  .pl-ls-7 {
    padding-left: 7rem;
  }
  .pr-ls-7 {
    padding-right: 7rem;
  }
  .pt-ls-7 {
    padding-top: 7rem;
  }
  .pb-ls-7 {
    padding-bottom: 7rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mt-mh-8 {
    margin-top: 8rem;
  }
  .mb-mh-8 {
    margin-bottom: 8rem;
  }
  .ml-mh-8 {
    margin-left: 8rem;
  }
  .mr-mh-8 {
    margin-right: 8rem;
  }
  .pt-mh-8 {
    padding-top: 8rem;
  }
  .pb-mh-8 {
    padding-bottom: 8rem;
  }
  .pl-mh-8 {
    padding-left: 8rem;
  }
  .pr-mh-8 {
    padding-right: 8rem;
  }
}
@media screen and (max-width: 479px) {
  .mt-mv-8 {
    margin-top: 8rem;
  }
  .mb-mv-8 {
    margin-bottom: 8rem;
  }
  .ml-mv-8 {
    margin-left: 8rem;
  }
  .mr-mv-8 {
    margin-right: 8rem;
  }
  .pt-mv-8 {
    padding-top: 8rem;
  }
  .pb-mv-8 {
    padding-bottom: 8rem;
  }
  .pl-mv-8 {
    padding-left: 8rem;
  }
  .pr-mv-8 {
    padding-right: 8rem;
  }
}
@media screen and (min-width: 991px) {
  .ml-dt-8 {
    margin-left: 8rem;
  }
  .mr-dt-8 {
    margin-right: 8rem;
  }
  .mt-dt-8 {
    margin-top: 8rem;
  }
  .mb-dt-8 {
    margin-bottom: 8rem;
  }
  .pl-dt-8 {
    padding-left: 8rem;
  }
  .pr-dt-8 {
    padding-right: 8rem;
  }
  .pt-dt-8 {
    padding-top: 8rem;
  }
  .pb-dt-8 {
    padding-bottom: 8rem;
  }
}
@media screen and (min-width: 2430px) {
  .ml-ls-8 {
    margin-left: 8rem;
  }
  .mr-ls-8 {
    margin-right: 8rem;
  }
  .mt-ls-8 {
    margin-top: 8rem;
  }
  .mb-ls-8 {
    margin-bottom: 8rem;
  }
  .pl-ls-8 {
    padding-left: 8rem;
  }
  .pr-ls-8 {
    padding-right: 8rem;
  }
  .pt-ls-8 {
    padding-top: 8rem;
  }
  .pb-ls-8 {
    padding-bottom: 8rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mt-mh-9 {
    margin-top: 9rem;
  }
  .mb-mh-9 {
    margin-bottom: 9rem;
  }
  .ml-mh-9 {
    margin-left: 9rem;
  }
  .mr-mh-9 {
    margin-right: 9rem;
  }
  .pt-mh-9 {
    padding-top: 9rem;
  }
  .pb-mh-9 {
    padding-bottom: 9rem;
  }
  .pl-mh-9 {
    padding-left: 9rem;
  }
  .pr-mh-9 {
    padding-right: 9rem;
  }
}
@media screen and (max-width: 479px) {
  .mt-mv-9 {
    margin-top: 9rem;
  }
  .mb-mv-9 {
    margin-bottom: 9rem;
  }
  .ml-mv-9 {
    margin-left: 9rem;
  }
  .mr-mv-9 {
    margin-right: 9rem;
  }
  .pt-mv-9 {
    padding-top: 9rem;
  }
  .pb-mv-9 {
    padding-bottom: 9rem;
  }
  .pl-mv-9 {
    padding-left: 9rem;
  }
  .pr-mv-9 {
    padding-right: 9rem;
  }
}
@media screen and (min-width: 991px) {
  .ml-dt-9 {
    margin-left: 9rem;
  }
  .mr-dt-9 {
    margin-right: 9rem;
  }
  .mt-dt-9 {
    margin-top: 9rem;
  }
  .mb-dt-9 {
    margin-bottom: 9rem;
  }
  .pl-dt-9 {
    padding-left: 9rem;
  }
  .pr-dt-9 {
    padding-right: 9rem;
  }
  .pt-dt-9 {
    padding-top: 9rem;
  }
  .pb-dt-9 {
    padding-bottom: 9rem;
  }
}
@media screen and (min-width: 2430px) {
  .ml-ls-9 {
    margin-left: 9rem;
  }
  .mr-ls-9 {
    margin-right: 9rem;
  }
  .mt-ls-9 {
    margin-top: 9rem;
  }
  .mb-ls-9 {
    margin-bottom: 9rem;
  }
  .pl-ls-9 {
    padding-left: 9rem;
  }
  .pr-ls-9 {
    padding-right: 9rem;
  }
  .pt-ls-9 {
    padding-top: 9rem;
  }
  .pb-ls-9 {
    padding-bottom: 9rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mt-mh-10 {
    margin-top: 10rem;
  }
  .mb-mh-10 {
    margin-bottom: 10rem;
  }
  .ml-mh-10 {
    margin-left: 10rem;
  }
  .mr-mh-10 {
    margin-right: 10rem;
  }
  .pt-mh-10 {
    padding-top: 10rem;
  }
  .pb-mh-10 {
    padding-bottom: 10rem;
  }
  .pl-mh-10 {
    padding-left: 10rem;
  }
  .pr-mh-10 {
    padding-right: 10rem;
  }
}
@media screen and (max-width: 479px) {
  .mt-mv-10 {
    margin-top: 10rem;
  }
  .mb-mv-10 {
    margin-bottom: 10rem;
  }
  .ml-mv-10 {
    margin-left: 10rem;
  }
  .mr-mv-10 {
    margin-right: 10rem;
  }
  .pt-mv-10 {
    padding-top: 10rem;
  }
  .pb-mv-10 {
    padding-bottom: 10rem;
  }
  .pl-mv-10 {
    padding-left: 10rem;
  }
  .pr-mv-10 {
    padding-right: 10rem;
  }
}
@media screen and (min-width: 991px) {
  .ml-dt-10 {
    margin-left: 10rem;
  }
  .mr-dt-10 {
    margin-right: 10rem;
  }
  .mt-dt-10 {
    margin-top: 10rem;
  }
  .mb-dt-10 {
    margin-bottom: 10rem;
  }
  .pl-dt-10 {
    padding-left: 10rem;
  }
  .pr-dt-10 {
    padding-right: 10rem;
  }
  .pt-dt-10 {
    padding-top: 10rem;
  }
  .pb-dt-10 {
    padding-bottom: 10rem;
  }
}
@media screen and (min-width: 2430px) {
  .ml-ls-10 {
    margin-left: 10rem;
  }
  .mr-ls-10 {
    margin-right: 10rem;
  }
  .mt-ls-10 {
    margin-top: 10rem;
  }
  .mb-ls-10 {
    margin-bottom: 10rem;
  }
  .pl-ls-10 {
    padding-left: 10rem;
  }
  .pr-ls-10 {
    padding-right: 10rem;
  }
  .pt-ls-10 {
    padding-top: 10rem;
  }
  .pb-ls-10 {
    padding-bottom: 10rem;
  }
}
.column-gap-0 {
  column-gap: 0rem;
}

.row-gap-0 {
  row-gap: 0rem;
}

.column-gap-1 {
  column-gap: 1rem;
}

.row-gap-1 {
  row-gap: 1rem;
}

.column-gap-2 {
  column-gap: 2rem;
}

.row-gap-2 {
  row-gap: 2rem;
}

.column-gap-3 {
  column-gap: 3rem;
}

.row-gap-3 {
  row-gap: 3rem;
}

.column-gap-4 {
  column-gap: 4rem;
}

.row-gap-4 {
  row-gap: 4rem;
}

.column-gap-5 {
  column-gap: 5rem;
}

.row-gap-5 {
  row-gap: 5rem;
}

.column-gap-6 {
  column-gap: 6rem;
}

.row-gap-6 {
  row-gap: 6rem;
}

.column-gap-7 {
  column-gap: 7rem;
}

.row-gap-7 {
  row-gap: 7rem;
}

.column-gap-8 {
  column-gap: 8rem;
}

.row-gap-8 {
  row-gap: 8rem;
}

.column-gap-9 {
  column-gap: 9rem;
}

.row-gap-9 {
  row-gap: 9rem;
}

.column-gap-10 {
  column-gap: 10rem;
}

.row-gap-10 {
  row-gap: 10rem;
}

.column-count-1 {
  column-count: 1;
}

.column-count-2 {
  column-count: 2;
}

.column-count-3 {
  column-count: 3;
}

.column-count-4 {
  column-count: 4;
}

.column-count-5 {
  column-count: 5;
}

.column-count-6 {
  column-count: 6;
}

.column-count-7 {
  column-count: 7;
}

.column-count-8 {
  column-count: 8;
}

.column-count-9 {
  column-count: 9;
}

.column-count-10 {
  column-count: 10;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .column-count-mh-1 {
    column-count: 1;
  }
}
@media screen and (max-width: 479px) {
  .column-count-mv-1 {
    column-count: 1;
  }
}
@media screen and (min-width: 991px) {
  .column-count-dt-1 {
    column-count: 1;
  }
}
@media screen and (min-width: 2430px) {
  .column-count-ls-1 {
    column-count: 1;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .column-count-mh-2 {
    column-count: 2;
  }
}
@media screen and (max-width: 479px) {
  .column-count-mv-2 {
    column-count: 2;
  }
}
@media screen and (min-width: 991px) {
  .column-count-dt-2 {
    column-count: 2;
  }
}
@media screen and (min-width: 2430px) {
  .column-count-ls-2 {
    column-count: 2;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .column-count-mh-3 {
    column-count: 3;
  }
}
@media screen and (max-width: 479px) {
  .column-count-mv-3 {
    column-count: 3;
  }
}
@media screen and (min-width: 991px) {
  .column-count-dt-3 {
    column-count: 3;
  }
}
@media screen and (min-width: 2430px) {
  .column-count-ls-3 {
    column-count: 3;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .column-count-mh-4 {
    column-count: 4;
  }
}
@media screen and (max-width: 479px) {
  .column-count-mv-4 {
    column-count: 4;
  }
}
@media screen and (min-width: 991px) {
  .column-count-dt-4 {
    column-count: 4;
  }
}
@media screen and (min-width: 2430px) {
  .column-count-ls-4 {
    column-count: 4;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .column-count-mh-5 {
    column-count: 5;
  }
}
@media screen and (max-width: 479px) {
  .column-count-mv-5 {
    column-count: 5;
  }
}
@media screen and (min-width: 991px) {
  .column-count-dt-5 {
    column-count: 5;
  }
}
@media screen and (min-width: 2430px) {
  .column-count-ls-5 {
    column-count: 5;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .column-count-mh-6 {
    column-count: 6;
  }
}
@media screen and (max-width: 479px) {
  .column-count-mv-6 {
    column-count: 6;
  }
}
@media screen and (min-width: 991px) {
  .column-count-dt-6 {
    column-count: 6;
  }
}
@media screen and (min-width: 2430px) {
  .column-count-ls-6 {
    column-count: 6;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .column-count-mh-7 {
    column-count: 7;
  }
}
@media screen and (max-width: 479px) {
  .column-count-mv-7 {
    column-count: 7;
  }
}
@media screen and (min-width: 991px) {
  .column-count-dt-7 {
    column-count: 7;
  }
}
@media screen and (min-width: 2430px) {
  .column-count-ls-7 {
    column-count: 7;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .column-count-mh-8 {
    column-count: 8;
  }
}
@media screen and (max-width: 479px) {
  .column-count-mv-8 {
    column-count: 8;
  }
}
@media screen and (min-width: 991px) {
  .column-count-dt-8 {
    column-count: 8;
  }
}
@media screen and (min-width: 2430px) {
  .column-count-ls-8 {
    column-count: 8;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .column-count-mh-9 {
    column-count: 9;
  }
}
@media screen and (max-width: 479px) {
  .column-count-mv-9 {
    column-count: 9;
  }
}
@media screen and (min-width: 991px) {
  .column-count-dt-9 {
    column-count: 9;
  }
}
@media screen and (min-width: 2430px) {
  .column-count-ls-9 {
    column-count: 9;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .column-count-mh-10 {
    column-count: 10;
  }
}
@media screen and (max-width: 479px) {
  .column-count-mv-10 {
    column-count: 10;
  }
}
@media screen and (min-width: 991px) {
  .column-count-dt-10 {
    column-count: 10;
  }
}
@media screen and (min-width: 2430px) {
  .column-count-ls-10 {
    column-count: 10;
  }
}