@use "../core/mixins" as mix;
@use "../core/variables" as var;

section {
    padding: 3rem 0;
    &.fh {
        min-height: 100vh;
    }
}
.container {
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: var.$box;
    &.super-slim {
        max-width: 800px;
    }
    &.slim {
        max-width: 900px;
    }
    &.narrow {
        max-width: 1080px;
    }
    &.wider {
        max-width: 1400px;
    }
    &.wide {
        max-width: 1600px;
    }
    &.widest {
        max-width: 1875px;
    }
    &.largescreen {
        max-width: 2430px;
    }
    &.fw {
        max-width: none !important;
    }
    @include mix.bdown(nm) {
        padding: 0 calc(15px + 2%);
    }
    @include mix.bdown(md) {
        padding: 0 calc(15px + 1%);
    }
}

.row {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    @include mix.bdown(md) {
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        &.eq-h {
            height: auto !important;
        }
    }
    &.no-collapse {
        @include mix.bdown(md) {
            display: flex;
            .col {
                margin-bottom: 0;
                // &[class^="basis"],
                // &[class*="basis"]{
                //     flex-grow: 0;
                // }
            }
        }
    }
}
.col {
    padding: 0 15px;
    flex-shrink: 0;
    box-sizing: border-box;
    &:not([class*="basis"]){
        flex-basis: 0;
        flex-grow: 1;
    }
    &[class*="basis"]{
        flex-grow: 0;
    }
    // @include bup(md){
    //     &[class^="basis"],
    //     &[class*="basis"]{
    //         flex-grow: 0;
    //     }
    // }
    @include mix.bdown(md) {
        margin-bottom: 1rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
.masonry {
    & > div {
        display: flex;
        flex-direction: column;
    }
    &-inner {
        border: 1px solid var.$gray;
        // height: 100%;
        padding: 1rem;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
        display: block;
        flex-grow: 1;
    }
    a.masonry-inner:hover {
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    }
    &-inverted {
        .masonry-inner {
            box-shadow: none;
        }
    }
    // & > div > a {
    //     display: block;
    //     height: 100%;
    // }
}