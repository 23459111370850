@use "../core/mixins" as mix;


// Paddings and margins
@for $i from 0 through 10 {
    .m-#{$i}{
        margin: #{$i}rem;
    }
    .p-#{$i}{
        padding: #{$i}rem;
    }
}
@for $i from 0 through 10 {
    .mv-#{$i}{
        margin-top: #{$i}rem;
        margin-bottom: #{$i}rem;
    }
    .pv-#{$i}{
        padding-top: #{$i}rem;
        padding-bottom: #{$i}rem;
    }
}
@for $i from 0 through 10 {
    .ms-#{$i}{
        margin-left: #{$i}rem;
        margin-right: #{$i}rem;
    }
    .ps-#{$i}{
        padding-left: #{$i}rem;
        padding-right: #{$i}rem;
    }
}
@for $i from 0 through 10 {
    .mt-#{$i}{
        margin-top: #{$i}rem;
    }
    .mb-#{$i}{
        margin-bottom: #{$i}rem;
    }
    .ml-#{$i}{
        margin-left: #{$i}rem;
    }
    .mr-#{$i}{
        margin-right: #{$i}rem;
    }
    .pt-#{$i}{
        padding-top: #{$i}rem;
    }
    .pb-#{$i}{
        padding-bottom: #{$i}rem;
    }
    .pl-#{$i}{
        padding-left: #{$i}rem;
    }
    .pr-#{$i}{
        padding-right: #{$i}rem;
    }
}

//0.5 without mq
.m {
    &-05 {
        margin: .5rem;
    }
    &s-05 {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    &v-05 {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    &r-05 {
        margin-right: 0.5rem;
    }
    &l-05 {
        margin-left: 0.5rem;
    }
    &t-05 {
        margin-top: 0.5rem;
    }
    &b-05 {
        margin-bottom: 0.5rem;
    }
}
.p {
    &-05 {
        padding: .5rem;
    }
    &s-05 {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    &v-05 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    &r-05 {
        padding-right: 0.5rem;
    }
    &l-05 {
        padding-left: 0.5rem;
    }
    &t-05 {
        padding-top: 0.5rem;
    }
    &b-05 {
        padding-bottom: 0.5rem;
    }
}


// 0 with mq
@include mix.bbetween(xs, md){
    .m {
        &-mh-0{
            margin: 0;
        }
        &s-mh-0{
            margin-left: 0;
            margin-right: 0;
        }
        &v-mh-0{
            margin-top: 0;
            margin-bottom: 0;
        }
        &t-mh-0{
            margin-top: 0;
        }
        &b-mh-0{
            margin-bottom: 0;
        }
        &l-mh-0{
            margin-left: 0;
        }
        &r-mh-0{
            margin-right: 0;
        }

    }
    .p {
        &-mh-0{
            padding: 0;
        }
        &s-mh-0{
            padding-left: 0;
            padding-right: 0;
        }
        &v-mh-0{
            padding-top: 0;
            padding-bottom: 0;
        }
        &t-mh-0{
            padding-top: 0;
        }
        &b-mh-0{
            padding-bottom: 0;
        }
        &l-mh-0{
            padding-left: 0;
        }
        &r-mh-00{
            padding-right: 0;
        }
    }
}
@include mix.bdown(xs){
    .m {
        
        &-mv-0{
            margin: 0;
        }
        &t-mv-0{
            margin-top: 0;
        }
        &b-mv-0{
            margin-bottom: 0;
        }
        &l-mv-0{
            margin-left: 0;
        }
        &r-mv-0{
            margin-right: 0;
        }
        &s-mv-0{
            margin-left: 0;
            margin-right: 0;
        }
        &v-mv-0{
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    
    .p {
        &t-mv-0{
            padding-top: 0;
        }
        &b-mv-0{
            padding-bottom: 0;
        }
        &l-mv-0{
            padding-left: 0;
        }
        &r-mv-0{
            padding-right: 0;
        }
        &s-mv-0{
            padding-left: 0;
            padding-right: 0;
        }
        &v-mv-0{
            padding-top: 0;
            padding-bottom: 0;
        }
        &-mv-0{
            padding: 0;
        }
    }
}
@include mix.bup(md){
    .m {
        &-dt-0{
            margin: 0;
        }
        &l-dt-0{
            margin-left: 0;
        }
        &r-dt-0{
            margin-right: 0;
        }
        &t-dt-0{
            margin-top: 0;
        }
        &b-dt-0{
            margin-bottom: 0;
        }
        &v-dt-0{
            margin-top: 0;
            margin-bottom: 0;
        }
        &s-dt-0{
            margin-left: 0;
            margin-right: 0;
        }
    }
    .p {
        &-dt-0{
            padding: 0;
        }
        &l-dt-0{
            padding-left: 0;
        }
        &r-dt-0{
            padding-right: 0;
        }
        &t-dt-0{
            padding-top: 0;
        }
        &b-dt-0{
            padding-bottom: 0;
        }
        &v-dt-0{
            padding-top: 0;
            padding-bottom: 0;
        }
        &s-dt-0{
            padding-left: 0;
            padding-right: 0;
        }
    }
}
@include mix.bup(ls){
    .m {
        &-ls-0{
            margin: 0;
        }
        &l-ls-0{
            margin-left: 0;
        }
        &r-ls-0{
            margin-right: 0;
        }
        &t-ls-0{
            margin-top: 0;
        }
        &b-ls-0{
            margin-bottom: 0;
        }
        &v-ls-0{
            margin-top: 0;
            margin-bottom: 0;
        }
        &s-ls-0{
            margin-left: 0;
            margin-right: 0;
        }
    }
    .p {
        &-ls-0{
            padding: 0;
        }
        &l-ls-0{
            padding-left: 0;
        }
        &r-ls-0{
            padding-right: 0;
        }
        &t-ls-0{
            padding-top: 0;
        }
        &b-ls-0{
            padding-bottom: 0;
        }
        &v-ls-0{
            padding-top: 0;
            padding-bottom: 0;
        }
        &s-ls-0{
            padding-left: 0;
            padding-right: 0;
        }
    }
}


// 0.5 with mq
@include mix.bbetween(xs, md){
    .m {
        &-mh {
            &-05 {
                margin: .5rem;
            }
        }
        &s-mh-05 {
            margin-left: .5rem;
            margin-right: .5rem;
        }
        &v-mh-05 {
            margin-top: .5rem;
            margin-bottom: .5rem;
        }
        &r-mh-05 {
            margin-right: .5rem;
        }
        &l-mh-05 {
            margin-left: .5rem;
        }
        &t-mh-05 {
            margin-top: .5rem;
        }
        &b-mh-05 {
            margin-bottom: .5rem;
        }
    }
    .p {
        &-mh {
            &-05 {
                padding: .5rem;
            }
        }
        &s-mh-05 {
            padding-left: .5rem;
            padding-right: .5rem;
        }
        &v-mh-05 {
            padding-top: .5rem;
            padding-bottom: .5rem;
        }
        &r-mh-05 {
            padding-right: .5rem;
        }
        &l-mh-05 {
            padding-left: .5rem;
        }
        &t-mh-05 {
            padding-top: .5rem;
        }
        &b-mh-05 {
            padding-bottom: .5rem;
        }
    }
}

@include mix.bdown(xs){
    .m {
        &-mv {
            &-05 {
                margin: .5rem;
            }
        }
        &s-mv-05 {
            margin-left: .5rem;
            margin-right: .5rem;
        }
        &v-mv-05 {
            margin-top: .5rem;
            margin-bottom: .5rem;
        }
        &r-mv-05 {
            margin-right: .5rem;
        }
        &l-mv-05 {
            margin-left: .5rem;
        }
        &t-mv-05 {
            margin-top: .5rem;
        }
        &b-mv-05 {
            margin-bottom: .5rem;
        }
    }
    .p {
        &-mv {
            &-05 {
                padding: .5rem;
            }
        }
        &s-mv-05 {
            padding-left: .5rem;
            padding-right: .5rem;
        }
        &v-mv-05 {
            padding-top: .5rem;
            padding-bottom: .5rem;
        }
        &r-mv-05 {
            padding-right: .5rem;
        }
        &l-mv-05 {
            padding-left: .5rem;
        }
        &t-mv-05 {
            padding-top: .5rem;
        }
        &b-mv-05 {
            padding-bottom: .5rem;
        }
    }
}

@include mix.bup(md){
    .m {
        &-dt {
            &-05 {
                margin: .5rem;
            }
        }
        &s-dt-05 {
            margin-left: .5rem;
            margin-right: .5rem;
        }
        &v-dt-05 {
            margin-top: .5rem;
            margin-bottom: .5rem;
        }
        &r-dt-05 {
            margin-right: .5rem;
        }
        &l-dt-05 {
            margin-left: .5rem;
        }
        &t-dt-05 {
            margin-top: .5rem;
        }
        &b-dt-05 {
            margin-bottom: .5rem;
        }
    }
    .p {
        &-dt {
            &-05 {
                padding: .5rem;
            }
        }
        &s-dt-05 {
            padding-left: .5rem;
            padding-right: .5rem;
        }
        &v-dt-05 {
            padding-top: .5rem;
            padding-bottom: .5rem;
        }
        &r-dt-05 {
            padding-right: .5rem;
        }
        &l-dt-05 {
            padding-left: .5rem;
        }
        &t-dt-05 {
            padding-top: .5rem;
        }
        &b-dt-05 {
            padding-bottom: .5rem;
        }
    }

}

@include mix.bup(ls){
    .m {
        &-ls {
            &-05 {
                margin: .5rem;
            }
        }
        &s-ls-05 {
            margin-left: .5rem;
            margin-right: .5rem;
        }
        &v-ls-05 {
            margin-top: .5rem;
            margin-bottom: .5rem;
        }
        &r-ls-05 {
            margin-right: .5rem;
        }
        &l-ls-05 {
            margin-left: .5rem;
        }
        &t-ls-05 {
            margin-top: .5rem;
        }
        &b-ls-05 {
            margin-bottom: .5rem;
        }
    }
    .p {
        &-ls {
            &-05 {
                padding: .5rem;
            }
        }
        &s-ls-05 {
            padding-left: .5rem;
            padding-right: .5rem;
        }
        &v-ls-05 {
            padding-top: .5rem;
            padding-bottom: .5rem;
        }
        &r-ls-05 {
            padding-right: .5rem;
        }
        &l-ls-05 {
            padding-left: .5rem;
        }
        &t-ls-05 {
            padding-top: .5rem;
        }
        &b-ls-05 {
            padding-bottom: .5rem;
        }
    }

}

@for $i from 1 through 10 {
    @include mix.bbetween(xs, md){
        .m-mh-#{$i}{
            margin: #{$i}rem;
        }
        .p-mh-#{$i}{
            padding: #{$i}rem;
        }
    }
    @include mix.bdown(xs){
        .m-mv-#{$i}{
            margin: #{$i}rem;
        }
        .p-mv-#{$i}{
            padding: #{$i}rem;
        }
    }
    @include mix.bup(md){
        .m-dt-#{$i}{
            margin: #{$i}rem;
        }
        .p-dt-#{$i}{
            padding: #{$i}rem;
        }
    }
    @include mix.bup(ls){
        .m-ls-#{$i}{
            margin: #{$i}rem;
        }
        .p-ls-#{$i}{
            padding: #{$i}rem;
        }
    }
}
@for $i from 1 through 10 {
    @include mix.bbetween(xs, md){
        .mv-mh-#{$i}{
            margin-top: #{$i}rem;
            margin-bottom: #{$i}rem;
        }
        .pv-mh-#{$i}{
            padding-top: #{$i}rem;
            padding-bottom: #{$i}rem;
        }
        .ms-mh-#{$i}{
            margin-left: #{$i}rem;
            margin-right: #{$i}rem;
        }
        .ps-mh-#{$i}{
            padding-left: #{$i}rem;
            padding-right: #{$i}rem;
        }
    }
    @include mix.bdown(xs){
        .mv-mv-#{$i}{
            margin-top: #{$i}rem;
            margin-bottom: #{$i}rem;
        }
        .pv-mv-#{$i}{
            padding-top: #{$i}rem;
            padding-bottom: #{$i}rem;
        }
        .ms-mv-#{$i}{
            margin-left: #{$i}rem;
            margin-right: #{$i}rem;
        }
        .ps-mv-#{$i}{
            padding-left: #{$i}rem;
            padding-right: #{$i}rem;
        }
    }
    @include mix.bup(md){
        .mv-dt-#{$i}{
            margin-top: #{$i}rem;
            margin-bottom: #{$i}rem;
        }
        .pv-dt-#{$i}{
            padding-top: #{$i}rem;
            padding-bottom: #{$i}rem;
        }
        .ms-dt-#{$i}{
            margin-left: #{$i}rem;
            margin-right: #{$i}rem;
        }
        .ps-dt-#{$i}{
            padding-left: #{$i}rem;
            padding-right: #{$i}rem;
        }
    }
    @include mix.bup(ls){
        .mv-ls-#{$i}{
            margin-top: #{$i}rem;
            margin-bottom: #{$i}rem;
        }
        .pv-ls-#{$i}{
            padding-top: #{$i}rem;
            padding-bottom: #{$i}rem;
        }
        .ms-ls-#{$i}{
            margin-left: #{$i}rem;
            margin-right: #{$i}rem;
        }
        .ps-ls-#{$i}{
            padding-left: #{$i}rem;
            padding-right: #{$i}rem;
        }
    }
}
@for $i from 1 through 10 {
    @include mix.bbetween(xs, md){
        .mt-mh-#{$i}{
            margin-top: #{$i}rem;
        }
        .mb-mh-#{$i}{
            margin-bottom: #{$i}rem;
        }
        .ml-mh-#{$i}{
            margin-left: #{$i}rem;
        }
        .mr-mh-#{$i}{
            margin-right: #{$i}rem;
        }

        .pt-mh-#{$i}{
            padding-top: #{$i}rem;
        }
        .pb-mh-#{$i}{
            padding-bottom: #{$i}rem;
        }
        .pl-mh-#{$i}{
            padding-left: #{$i}rem;
        }
        .pr-mh-#{$i}{
            padding-right: #{$i}rem;
        }
    }
    @include mix.bdown(xs){
        .mt-mv-#{$i}{
            margin-top: #{$i}rem;
        }
        .mb-mv-#{$i}{
            margin-bottom: #{$i}rem;
        }
        .ml-mv-#{$i}{
            margin-left: #{$i}rem;
        }
        .mr-mv-#{$i}{
            margin-right: #{$i}rem;
        }
        
        .pt-mv-#{$i}{
            padding-top: #{$i}rem;
        }
        .pb-mv-#{$i}{
            padding-bottom: #{$i}rem;
        }
        .pl-mv-#{$i}{
            padding-left: #{$i}rem;
        }
        .pr-mv-#{$i}{
            padding-right: #{$i}rem;
        }
    }
    @include mix.bup(md){
        .ml-dt-#{$i}{
            margin-left: #{$i}rem;
        }
        .mr-dt-#{$i}{
            margin-right: #{$i}rem;
        }
        .mt-dt-#{$i}{
            margin-top: #{$i}rem;
        }
        .mb-dt-#{$i}{
            margin-bottom: #{$i}rem;
        }
        .pl-dt-#{$i}{
            padding-left: #{$i}rem;
        }
        .pr-dt-#{$i}{
            padding-right: #{$i}rem;
        }
        .pt-dt-#{$i}{
            padding-top: #{$i}rem;
        }
        .pb-dt-#{$i}{
            padding-bottom: #{$i}rem;
        }
    }  
    @include mix.bup(ls){
        .ml-ls-#{$i}{
            margin-left: #{$i}rem;
        }
        .mr-ls-#{$i}{
            margin-right: #{$i}rem;
        }
        .mt-ls-#{$i}{
            margin-top: #{$i}rem;
        }
        .mb-ls-#{$i}{
            margin-bottom: #{$i}rem;
        }
        .pl-ls-#{$i}{
            padding-left: #{$i}rem;
        }
        .pr-ls-#{$i}{
            padding-right: #{$i}rem;
        }
        .pt-ls-#{$i}{
            padding-top: #{$i}rem;
        }
        .pb-ls-#{$i}{
            padding-bottom: #{$i}rem;
        }
    }
}

// Column gap and row gap
@for $i from 0 through 10 {
    .column-gap-#{$i}{
        column-gap: #{$i}rem;
    }
    .row-gap-#{$i}{
        row-gap: #{$i}rem;
    }
}

// Column count
@for $i from 1 through 10 {
    .column-count-#{$i}{
        column-count: $i;
    }
}
@for $i from 1 through 10 {
    @include mix.bbetween(xs, md){
        .column-count-mh-#{$i}{
            column-count: $i;
        }
    }
    @include mix.bdown(xs){
        .column-count-mv-#{$i}{
            column-count: $i;
        }
    }
    @include mix.bup(md){
        .column-count-dt-#{$i}{
            column-count: $i;
        }
    }
    @include mix.bup(ls){
        .column-count-ls-#{$i}{
            column-count: $i;
        }
    }
}


